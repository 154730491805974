import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { LANGUAGES } from '../../constants';
import httpService from '../../services/HttpService';
import { btnBase } from '../../styles/buttons';
import { sidebarItemStyle } from '../../styles/components/SidebarStyle';
import colors from '../../styles/constants/colors';
import variables from '../../styles/constants/variables';
import { uBkgWhite, uClrDark } from '../../styles/helpers';

const LanguagePickerOption = styled.button`
  ${btnBase};
  ${uClrDark};
  background-color: transparent;
`;

const LanguageMenu = styled.div`
  position: absolute;
  top: 0;
  transform: translateX(-90%);
  z-index: 3;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 ${variables.gutters.gut10};
  ${uClrDark};
  ${uBkgWhite};
  border-radius: 3px;

  button {
    ${uClrDark};

    &[disabled] {
      color: ${colors.orange};
    }
  }
`;

const LanguagePicker = () => {
  const history = useHistory();
  const [isLanguageMenuOpen, openLanguageMenu] = useState(false);
  const { i18n, t } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem('lang') || i18n.language
  );
  const langMenuRef = useRef();

  useEffect(
    () => {
      const closeDropdown = event => {
        if (
          isLanguageMenuOpen &&
          langMenuRef.current &&
          !langMenuRef.current.contains(event.target)
        ) {
          openLanguageMenu(false);
        }
      };
      document.addEventListener('click', closeDropdown, false);
      return () => {
        document.removeEventListener('click', closeDropdown, false);
      };
    },
    [isLanguageMenuOpen]
  );

  const handleLangChange = () => {
    const lang = selectedLang === LANGUAGES.EN ? LANGUAGES.SR : LANGUAGES.EN;
    localStorage.setItem('lang', lang);
    httpService.attachHeaders({ 'Accept-Language': lang });

    const current = history.location.pathname;

    i18n.changeLanguage(lang);
    openLanguageMenu(false);
    setSelectedLang(lang);

    history.push('/reload');
    setTimeout(() => {
      history.push(current);
    }, 3000);
  };

  return (
    <div css={sidebarItemStyle} className="nav-link">
      <LanguagePickerOption
        onClick={() => openLanguageMenu(true)}
        onKeyDown={() => openLanguageMenu(true)}
      >
        {t(`common.${selectedLang}`)}
      </LanguagePickerOption>
      {isLanguageMenuOpen && (
        <LanguageMenu ref={langMenuRef}>
          {Object.keys(LANGUAGES).map(key => (
            <LanguagePickerOption
              onClick={handleLangChange}
              onKeyDown={handleLangChange}
              disabled={LANGUAGES[key] === selectedLang}
              type="button"
            >
              {t(`common.${LANGUAGES[key]}`)}
            </LanguagePickerOption>
          ))}
        </LanguageMenu>
      )}
    </div>
  );
};

LanguagePicker.propTypes = {};

export default LanguagePicker;
