import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  activeBcgColor,
  activeColor,
  firstNavItemStyle,
  iconStyle,
  inactiveColor,
  sidebarItemStyle,
  sidebarItemText
} from '../../../styles/components/SidebarStyle';
import colors from '../../../styles/constants/colors';
import Image from '../Image/Image';
import NavTitle from './NavTitle';

const NavItem = ({ link, isActive, isFirst }) => {
  if (link.action) {
    return (
      <button
        css={{ color: colors.black }}
        key={link.name}
        className="nav-link"
        onClick={link.action}
        type="button"
      >
        <Image src={link.logo} />
      </button>
    );
  }

  const getIcon = () => {
    if (!isActive) {
      return link.Icon;
    }

    if (link.ActiveIcon) {
      return link.ActiveIcon;
    }

    return link.Icon;
  };
  const Icon = getIcon();

  return (
    <Link
      css={[
        sidebarItemStyle,
        isActive ? activeBcgColor : '',
        isFirst && firstNavItemStyle
      ]}
      key={link.to}
      className="nav-link"
      to={link.to}
    >
      <Icon
        css={iconStyle}
        fill={isActive ? colors.white : colors.yankeesBlue}
      />
      <NavTitle
        style={[sidebarItemText, isActive ? activeColor : inactiveColor]}
        hideTitle={link.hideName}
        title={link.name}
      />
    </Link>
  );
};

NavItem.propTypes = {
  link: PropTypes.shape({
    to: PropTypes.string,
    logo: PropTypes.string,
    title: PropTypes.string,
    hideName: PropTypes.bool,
    Icon: PropTypes.node,
    ActiveIcon: PropTypes.node,
    name: PropTypes.string,
    action: PropTypes.func
  }),
  isActive: PropTypes.bool,
  isFirst: PropTypes.bool
};

export default NavItem;
