import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  imageStyle,
  itemBtnStyle,
  ItemContainer,
  ItemText,
  ItemTitle,
  ItemWrapper,
  regularImgStyle
} from '../../../styles/components/ResidentItemStyle';
import { gridHalf } from '../../../styles/grid';
import { uGapRight15 } from '../../../styles/helpers';
import { parseDistrictAndFloors } from '../../../utils/parseDistrictAndFloors';
import ResidentLogo from '../../shared/ResidentLogo';

const ResidentItem = ({ item, onClick, onFocus }) => {
  const { t } = useTranslation();
  const isLuxury = item.properties && item.properties.is_luxury;

  return (
    <div css={gridHalf}>
      <ItemWrapper type="button" onClick={() => onClick(item.slug)}>
        <ResidentLogo
          style={[imageStyle, regularImgStyle]}
          css={imageStyle}
          src={item.logo_url}
          isLuxury={isLuxury}
          title={item.title}
          isLazyLoad
        />
        <ItemContainer>
          <ItemTitle>{item.title}</ItemTitle>
          <ItemText>
            {parseDistrictAndFloors(item.districts, item.floors)}
          </ItemText>
          <button
            onClick={event => onFocus(event, item)}
            type="button"
            css={[uGapRight15, itemBtnStyle]}
          >
            {t('common.map')}
          </button>
        </ItemContainer>
      </ItemWrapper>
    </div>
  );
};

ResidentItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  onFocus: PropTypes.func
};

export default ResidentItem;
