import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RESIDENT_TYPES } from '../../../constants';
import { isOnlineSelector } from '../../../redux/selectors/networkSelector';
import { btnBaseWithoutPseudo } from '../../../styles/buttons';
import { ContainerGapRight } from '../../../styles/container';
import {
  uCenterBlockElement,
  uGapBottom50,
  uTextUppercase
} from '../../../styles/helpers';
import Loading from '../../loader/Loading';
import ListWithEmptyState from './ListWithEmptyState';
import ResidentLetterFilter from './ResidentLetterFilter';
import ResidentList from './ResidentList';

const ResidentListContainer = ({
  list,
  info,
  type,
  handleItemClick,
  selectedLetter,
  setSelectedLetter,
  handleLoadMore,
  hasMore,
  count
}) => {
  const { t } = useTranslation();
  const online = useSelector(isOnlineSelector);
  const getTypeText = useCallback(
    () => {
      switch (type) {
      case RESIDENT_TYPES.SHOP:
        return t('resident.store');
      case RESIDENT_TYPES.DINE:
        return t('resident.restaurant');
      case RESIDENT_TYPES.ENTERTAINMENT:
        return t('resident.entertainment_plural');
      default:
        return '';
      }
    },
    [t, type]
  );

  const renderCountText = useMemo(
    () => {
      if (info.total !== count) {
        return (
          <h4 css={[uGapBottom50, uTextUppercase]}>
            {getTypeText()}
            <strong> {count} </strong>
            {t('resident.of')}
            <strong> {info.total || 0} </strong>
          </h4>
        );
      }

      return (
        <h4 css={[uGapBottom50, uTextUppercase]}>
          {t('resident.count', {
            type: getTypeText()
          })}
          <strong> {info.total || 0} </strong>
        </h4>
      );
    },
    [info, list, getTypeText, t]
  );

  return (
    <div css={ContainerGapRight}>
      {renderCountText}
      <ResidentLetterFilter
        list={info.letters}
        selectedItem={selectedLetter}
        setSelectedItem={setSelectedLetter}
      />
      {!online && <Loading type={type} />}
      <ListWithEmptyState
        isEmpty={info && info.total > 0 && !list.length}
        title={t('resident.noResultsTitle')}
        description={t('resident.noResultsDescription')}
      >
        <ResidentList onItemClick={handleItemClick} items={list} type={type} />
      </ListWithEmptyState>
      {hasMore && (
        <button
          css={[btnBaseWithoutPseudo, uCenterBlockElement]}
          type="button"
          onClick={handleLoadMore}
        >
          {t('resident.loadMore')}
        </button>
      )}
    </div>
  );
};

ResidentListContainer.propTypes = {
  list: PropTypes.array,
  info: PropTypes.object,
  type: PropTypes.string,
  handleItemClick: PropTypes.func,
  selectedLetter: PropTypes.string,
  setSelectedLetter: PropTypes.func,
  handleLoadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  count: PropTypes.number
};

export default ResidentListContainer;
