import { DEFUALT_SPLASH_SLIDER_SPEED } from '../../constants/sliderSettings';
import { isVideo } from '../../utils';
import {
  BLOBS_SET,
  INFO_SECTION_SET,
  SPLASH_SCREEN_SET
} from '../actions/infoSectionActions';
import createReducer from '../util';

const initialState = {
  services: [],
  splash: [],
  blobs: []
};
export default createReducer(
  {
    [INFO_SECTION_SET]: setInfoSection,
    [SPLASH_SCREEN_SET]: splashScreenSet,
    [BLOBS_SET]: blobsSet
  },
  initialState
);

function setInfoSection(state, action) {
  return {
    ...state,
    services: action.payload
  };
}

function splashScreenSet(state, action) {
  return {
    ...state,
    splash: !action.payload
      ? []
      : action.payload.map(splash => ({
        ...splash,
        isVideo: isVideo(splash.image),
        duration: splash.duration
          ? splash.duration * 1000
          : DEFUALT_SPLASH_SLIDER_SPEED
      }))
  };
}

function blobsSet(state, action) {
  return {
    ...state,
    blobs: action.payload,
    splash: state.splash.map((splash, index) => ({
      ...splash,
      image: splash.isVideo
        ? URL.createObjectURL(action.payload[index])
        : splash.image
    }))
  };
}
