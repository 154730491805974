import { all, call, put, takeLatest } from 'redux-saga/effects';

import { floorService } from '../../services/FloorService';
import { getAllFloors } from '../../utils/get-all-floors';
import { GET_FLOORS, setFloors, setFloorsError } from '../actions/floorActions';

export function* fetchFloors() {
  try {
    let allFloors = getAllFloors();
    allFloors = allFloors === 'true' ? true : null;
    const { data } = yield call(floorService.getFloors, allFloors);
    yield put(setFloors(data.results));
  } catch (e) {
    yield put(setFloorsError());
  }
}

export default function* floorSaga() {
  yield all([takeLatest(GET_FLOORS, fetchFloors)]);
}
