import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

import { defaultSliderSettings } from '../../../constants/sliderSettings';
import { uWidthFull } from '../../../styles/helpers';

const ModalSlider = ({
  children,
  slidesToShow,
  slidesToScroll,
  variableWidth
}) => {
  const settings = {
    ...defaultSliderSettings,
    dots: false,
    slidesToShow,
    slidesToScroll,
    autoplay: true,
    infinite: true,
    variableWidth
  };

  return (
    <div css={uWidthFull}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

ModalSlider.propTypes = {
  children: PropTypes.node,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  variableWidth: PropTypes.bool
};

ModalSlider.defaultProps = {
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true
};

export default ModalSlider;
