import { css } from '@emotion/core';

import colors from './constants/colors';
import fonts, { baseFontSize } from './constants/fonts';
import variables from './constants/variables';

export default css`
  @font-face {
    font-family: ${fonts.family.bold};
    font-weight: ${fonts.weight.bold};
    src: url('/fonts/GalerijaJosefinSans-Bold.otf');
  }

  @font-face {
    font-family: ${fonts.family.boldItalic};
    font-weight: ${fonts.weight.bold};
    font-style: italic;
    src: url('/fonts/GalerijaJosefinSans-BoldItalic.otf');
  }

  @font-face {
    font-family: ${fonts.family.italic};
    font-weight: ${fonts.weight.regular};
    font-weight: italic
    src: url('/fonts/GalerijaJosefinSans-Italic.otf');
  }

  @font-face {
    font-family: ${fonts.family.light};
    font-weight: ${fonts.weight.light};
    src: url('/fonts/GalerijaJosefinSans-Light.otf');
  }

  @font-face {
    font-family: ${fonts.family.lightItalic};
    font-weight: ${fonts.weight.regular};
    font-style: italic;
    src: url('/fonts/GalerijaJosefinSans-LightItalic.otf');
  }

  @font-face {
    font-family: ${fonts.family.medium};
    font-weight: ${fonts.weight.medium};
    src: url('/fonts/GalerijaJosefinSans-Medium.otf');
  }

  @font-face {
    font-family: ${fonts.family.mediumItalic};
    font-weight: ${fonts.weight.medium};
    font-style: italic;
    src: url('/fonts/GalerijaJosefinSans-MediumItalic.otf');
  }

  @font-face {
    font-family: ${fonts.family.regular};
    font-weight: ${fonts.weight.regular};
    src: url('/fonts/GalerijaJosefinSans-Regular.otf');
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    + * {
      box-sizing: inherit;
    }
  }

  html {
    font-size: ${baseFontSize};
    font-family: ${fonts.family.regular};
    line-height: ${fonts.lineHeight.base};
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 100vh;

    &::-webkit-scrollbar {
      display: none;
    }
  
    div {
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  body {
    background-color: ${colors.yankeesBlue};
    color: ${colors.white};
    font-size: ${baseFontSize};
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 100%;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 

    &::-webkit-scrollbar {
      display: none;
    }

    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  button {
    &:focus {
      outline: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-family: ${fonts.family.bold};

    em {
      font-family: ${fonts.family.boldItalic};
    }
  }

  h1 {
    font-size: ${fonts.size.h1};
  }

  h2 {
    font-size: ${fonts.size.h2m};
  }

  h3 {
    font-size: ${fonts.size.h3};
    font-family: ${fonts.family.medium};
  }

  h4 {
    font-size: ${fonts.size.h4};
  }

  h5 {
    font-size: ${fonts.size.h5};
  }

  h6 {
    font-size: ${fonts.size.h6};
  }

  p {
    margin-bottom: ${variables.gutters.base};
  }

  ul {
    list-style-type: none;
    margin-left: 0;

    > li {
      &:not(:last-child) {
        margin-bottom: ${variables.gutters.sm};
      }
    }
  }

  em {
    font-family: ${fonts.family.italic};
  }

  img {
    width: 100%;
    max-width: 100%;
  }

  small {
    font-size: ${fonts.size.xs};
  }

  a {
    color: ${colors.black};
    text-decoration: none;
  }

  #__next {
    display: contents;
  }

  svg {
      width: 100%;
      height: 100%;
  }
`;
