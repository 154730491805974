import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { uBkgDark } from '../../styles/helpers';
import Video from '../common/video/Video';

const VideoContainer = styled.div`
  ${uBkgDark};
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
  }
`;

const SplashVideo = forwardRef(({ video, ...props }, ref) => {
  return (
    <VideoContainer>
      <Video src={video} ref={ref} {...props} />
    </VideoContainer>
  );
});

SplashVideo.propTypes = {
  video: PropTypes.string
};

export default SplashVideo;
