import { css } from '@emotion/core';
import styled from '@emotion/styled';

import variables from './constants/variables';

export const Header = styled.div`
  min-height: ${variables.sizes.headerHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${variables.gutters.gut30};
`;

export const headerSm = css`
  min-height: ${variables.sizes.headerHeightSm};
  padding: ${variables.gutters.gut30};
`;

export const PageContainer = styled.div`
  padding: ${variables.gutters.gut50} ${variables.gutters.gut30}
    ${variables.gutters.gut30};
`;

export const ContainerGapRight = css`
  padding-right: ${variables.sizes.sidebarWidth};
`;

export const PageTopPadding = css`
  padding-top: ${variables.gutters.gut30};
`;

export const PageRightPadding = css`
  padding-right: ${variables.sizes.sidebarWidth};
`;

export const PageRightPaddingSm = css`
  padding-right: calc(
    ${variables.sizes.sidebarWidth} - ${variables.gutters.gut30}
  );
`;
