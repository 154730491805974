import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';

import colors from '../../styles/constants/colors';
import Image from '../common/Image/Image';
import SvgImage from '../common/Image/SvgImage';

const EmptyResidentLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => (props.isLuxury ? colors.white : colors.black)};
  background-color: ${props =>
    props.isLuxury ? colors.black : colors.whiteDirty};
`;

const EmptyResidentLogo = ({ style, isLuxury, title }) => {
  return (
    <EmptyResidentLogoContainer isLuxury={isLuxury} css={style}>
      {title}
    </EmptyResidentLogoContainer>
  );
};

EmptyResidentLogo.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
  isLuxury: PropTypes.bool
};

const ResidentLogo = ({ src, style, title, isLuxury = false, ...props }) => {
  if (!src) {
    return (
      <EmptyResidentLogo style={style} title={title} isLuxury={isLuxury} />
    );
  }

  const isSvg = src && src.substr(src.length - 3) === 'svg';

  if (!isSvg) {
    return <Image src={src} css={style} isLazyLoad />;
  }

  return (
    <LazyLoad once height={72}>
      <SvgImage src={src} style={style} isLuxury={isLuxury} {...props} />
    </LazyLoad>
  );
};

ResidentLogo.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  isLuxury: PropTypes.bool
};

export default ResidentLogo;
