import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { setFocusedResidentItem } from '../../redux/actions/mapActions';
import { floorsSelector } from '../../redux/selectors/floorSelectors';
import { selectedResidentSelector } from '../../redux/selectors/mapSelectors';
import { isOnlineSelector } from '../../redux/selectors/networkSelector';
import mapService from '../../services/MapService';
import {
  MapOptions,
  optionsTitle,
  shopBtn
} from '../../styles/components/MapScreen';
import MapFloors from '../common/Map/MapFloors';
import MapIframe from '../common/Map/MapIframe';

export const ROUTES = {
  SHOP: 'shops',
  DINE: 'dine',
  ENTERTAINMENT: 'entertainments'
};

const Maps = ({ isFocused }) => {
  const dispatch = useDispatch();
  const floors = useSelector(floorsSelector);
  const mapRef = useRef(null);
  const { t } = useTranslation();
  const online = useSelector(isOnlineSelector);
  const [shouldReload, setShouldReload] = useState(false);
  const [isMapReady, setIsMapReady] = useState(false);
  const [activeFloor, setActiveFloor] = useState(null);
  const [goToResident, setGoToResident] = useState(null);
  const focusedResidentFromSearch = useSelector(selectedResidentSelector);

  const selectFloor = floor => {
    setActiveFloor(floor.id);
    mapService.changeFloor(floor);
  };

  const selectFloorId = floorId => {
    setActiveFloor(floorId);
  };

  const focusResident = useCallback(
    ({ id, floor, type, slug }) => {
      if (!mapRef.current || !isMapReady) {
        return;
      }

      mapService.changeFloor(floor);
      setActiveFloor(floor.id);

      mapService.navigateToResident(id, type);
      setGoToResident({ residentSlug: slug, type, floor });
    },
    [isMapReady]
  );

  useEffect(
    () => {
      if (online && shouldReload) {
        mapService.reloadIframe();
        setShouldReload(false);
      } else if (!online) {
        setShouldReload(true);
      }
    },
    [online]
  );

  useEffect(
    () => {
      if (mapRef.current) {
        mapService.storeRef(mapRef.current);
      }
    },
    [mapRef]
  );

  useEffect(
    () => {
      if (floors.length) {
        selectFloor(activeFloor || floors[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [floors]
  );

  const resetMap = useCallback(
    () => {
      dispatch(setFocusedResidentItem(null));
      mapService.resetMap();
      setGoToResident(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, floors]
  );

  useEffect(
    () => {
      if (!isFocused) {
        resetMap();
      }
      if (focusedResidentFromSearch) {
        focusResident({
          ...focusedResidentFromSearch,
          floor: focusedResidentFromSearch.floors[0]
        });
      }
    },
    [focusedResidentFromSearch, isFocused, isMapReady, focusResident, resetMap]
  );

  return (
    <>
      <MapOptions>
        <p css={optionsTitle}>{t('map.helpMeFind')}</p>

        <Link css={shopBtn} to={ROUTES.SHOP}>
          {t('map.shops')}
        </Link>
        <Link css={shopBtn} to={ROUTES.DINE}>
          {t('map.dine')}
        </Link>
        <Link css={shopBtn} to={ROUTES.ENTERTAINMENT}>
          {t('map.entertain')}
        </Link>

        <MapFloors
          onSelect={selectFloor}
          activeFloor={activeFloor}
          focusedFloor={goToResident ? goToResident.floor : null}
        />
      </MapOptions>
      <MapIframe
        ref={mapRef}
        goToResident={data => setGoToResident({ ...data, floor: activeFloor })}
        changeFloor={selectFloorId}
        onReady={() => setIsMapReady(true)}
      />
    </>
  );
};

Maps.propTypes = {
  isFocused: PropTypes.bool
};

export default Maps;
