export const DINES_GET = 'DINES_GET';
export const DINES_SET = 'DINES_SET';

export const DINES_HAVE_YOU_TRIED_SET = 'DINES_HAVE_YOU_TRIED_SET';

export const DINE_GET = 'DINE_GET';

export const DINES_INFO_GET = 'DINES_INFO_GET';
export const DINES_INFO_SET = 'DINES_INFO_SET';

export const DINE_CATEGORIES_GET = 'DINE_CATEGORIES_GET';
export const DINE_CATEGORIES_SET = 'DINE_CATEGORIES_SET';

export const DINE_SELECT_CATEGORY_SET = 'DINE_SELECT_CATEGORY_SET';
export const DINE_SELECT_FLOOR_SET = 'DINE_SELECT_FLOOR_SET';

export const DINES_FILTER = 'DINES_FILTER';
export const DINES_APPEND = 'DINES_APPEND';

export const DINES_RESET = 'DINES_RESET';

export const dineGet = payload => ({
  type: DINE_GET,
  payload
});

export const dinesGet = payload => ({
  type: DINES_GET,
  payload
});

export const dinesSet = payload => ({
  type: DINES_SET,
  payload
});

export const dinesHaveYouTriedSet = payload => ({
  type: DINES_HAVE_YOU_TRIED_SET,
  payload
});

export const dinesInfoGet = () => ({
  type: DINES_INFO_GET
});

export const dinesInfoSet = payload => ({
  type: DINES_INFO_SET,
  payload
});

export const dineCategoriesGet = () => {
  return {
    type: DINE_CATEGORIES_GET
  };
};

export const dineCategoriesSet = payload => ({
  type: DINE_CATEGORIES_SET,
  payload
});

export const dineSelectCategorySet = payload => ({
  type: DINE_SELECT_CATEGORY_SET,
  payload
});

export const dineSelectedFloorSet = payload => ({
  type: DINE_SELECT_FLOOR_SET,
  payload
});

export const filterDines = payload => ({
  type: DINES_FILTER,
  payload
});

export const appendDines = payload => ({
  type: DINES_APPEND,
  payload
});

export const resetDines = () => ({
  type: DINES_RESET
});
