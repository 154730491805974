import i18n from '../i18n';

export const EVENT_DATE_KEYS = {
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  THIS_WEEK: 'thisweek',
  THIS_WEEKED: 'thisweekend',
  NEXT_WEEK: 'nextweek',
  THIS_MONTH: 'thismonth',
  NEXT_MONTH: 'nextmonth',
  SOON: 'soon'
};

export const makeEventsFilter = () => ({
  TODAY: {
    label: i18n.t('dateMoments.today'),
    keysToFilter: [EVENT_DATE_KEYS.TODAY]
  },
  THIS_WEEK: {
    label: i18n.t('dateMoments.thisWeek'),
    keysToFilter: [
      EVENT_DATE_KEYS.TODAY,
      EVENT_DATE_KEYS.TOMORROW,
      EVENT_DATE_KEYS.THIS_WEEK,
      EVENT_DATE_KEYS.THIS_WEEKED
    ]
  },
  NEXT_WEEK: {
    label: i18n.t('dateMoments.nextWeek'),
    keysToFilter: [EVENT_DATE_KEYS.NEXT_WEEK]
  },
  THIS_WEEKED: {
    label: i18n.t('dateMoments.thisWeekend'),
    keysToFilter: [EVENT_DATE_KEYS.THIS_WEEKED]
  },
  THIS_MONTH: {
    label: i18n.t('dateMoments.thisMonth'),
    keysToFilter: [
      EVENT_DATE_KEYS.TODAY,
      EVENT_DATE_KEYS.TOMORROW,
      EVENT_DATE_KEYS.THIS_WEEK,
      EVENT_DATE_KEYS.THIS_WEEKED,
      EVENT_DATE_KEYS.THIS_MONTH
    ]
  }
});
