export const ENTERTAINMENTS_SET = 'ENTERTAINMENTS_SET';
export const ENTERTAINMENTS_GET = 'ENTERTAINMENTS_GET';
export const ENTERTAINMENT_GET = 'ENTERTAINMENT_GET';
export const ENTERTAINMENT_ERROR = 'ENTERTAINMENT_ERROR';
export const ENTERTAINMENT_INFO_GET = 'ENTERTAINMENT_INFO_GET';
export const ENTERTAINMENT_INFO_SET = 'ENTERTAINMENT_INFO_SET';
export const ENTERTAINMENT_SELECT_CATEGORY_SET =
  'ENTERTAINMENT_SELECT_CATEGORY_SET';
export const ENTERTAINMENT_SELECT_FLOOR_SET = 'ENTERTAINMENT_SELECT_FLOOR_SET';
export const ENTERTAINMENT_CATEGORIES_SET = 'ENTERTAINMENT_CATEGORIES_SET';
export const ENTERTAINMENT_CATEGORIES_GET = 'ENTERTAINMENT_CATEGORIES_GET';
export const ENTERTAINMENT_TOP_SET = 'ENTERTAINMENT_TOP_SET';
export const ENTERTAINMENT_TOP_GET = 'ENTERTAINMENT_TOP_GET';
export const ENTERTAINMENTS_FILTER = 'ENTERTAINMENTS_FILTER';
export const ENTERTAINMENTS_RESET = 'ENTERTAINMENTS_RESET';
export const ENTERTAINMENTS_APPEND = 'ENTERTAINMENTS_APPEND';

export const setEntertainments = payload => ({
  type: ENTERTAINMENTS_SET,
  payload
});
export const getEntertainments = payload => ({
  type: ENTERTAINMENTS_GET,
  payload
});

export const getEntertainment = payload => ({
  type: ENTERTAINMENT_GET,
  payload
});

export const setEntertainmentError = payload => ({
  type: ENTERTAINMENT_ERROR,
  payload
});

export const entertainmentsInfoGet = () => ({
  type: ENTERTAINMENT_INFO_GET
});

export const entertainmentsInfoSet = payload => ({
  type: ENTERTAINMENT_INFO_SET,
  payload
});

export const entertainmentCategoriesSet = payload => ({
  type: ENTERTAINMENT_CATEGORIES_SET,
  payload
});

export const entertainmentCategoriesGet = () => ({
  type: ENTERTAINMENT_CATEGORIES_GET
});

export const entertainmentSelectedFloorSet = payload => ({
  type: ENTERTAINMENT_SELECT_FLOOR_SET,
  payload
});

export const entertainmentSelectedCategorySet = payload => ({
  type: ENTERTAINMENT_SELECT_CATEGORY_SET,
  payload
});

export const setTopEntertainments = payload => ({
  type: ENTERTAINMENT_TOP_SET,
  payload
});

export const filterEntertainments = payload => ({
  type: ENTERTAINMENTS_FILTER,
  payload
});

export const resetEntertainments = () => ({
  type: ENTERTAINMENTS_RESET
});

export const appendEntertainments = payload => ({
  type: ENTERTAINMENTS_APPEND,
  payload
});

export const getTopEntertainments = () => ({
  type: ENTERTAINMENT_TOP_GET
});
