import { OFFER_LIMIT } from '../../constants/offers';
import { OFFERS_SET } from '../actions/offerActions';
import createReducer from '../util';

const initialState = {
  offers: [],
  hasMore: true
};

const setOffers = (state, action) => {
  const { offers, offset } = action.payload;
  const hasMore = offers.length === OFFER_LIMIT;

  return {
    ...state,
    hasMore,
    offers: offset === 0 ? offers : [...state.offers, ...offers]
  };
};

export default createReducer(
  {
    [OFFERS_SET]: setOffers
  },
  initialState
);
