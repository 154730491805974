import createReducer from '../../util';
import {
  ALERT_NOTIFICATION_ERROR,
  ALERT_NOTIFICATION_SET,
} from '../../actions/commonActions';

const initialState = {
  alertNotification: '',
  alertNotificationError: ''
};

export default createReducer({
  [ALERT_NOTIFICATION_SET]: setAlertNotification,
  [ALERT_NOTIFICATION_ERROR]: setAlertNotificationError,
}, initialState)

function setAlertNotification(state, action) {
  return {
    ...state,
    alertNotification: action.payload
  }
}

function setAlertNotificationError(state, action) {
  return {
    ...state,
    alertNotificationError: action.payload,
  }
}
