import { IS_ONLINE_SET } from '../actions/networkActions';
import createReducer from '../util';

const initialState = {
  online: navigator.onLine
};

export default createReducer(
  {
    [IS_ONLINE_SET]: setIsOnline
  },
  initialState
);

function setIsOnline(state, action) {
  return {
    ...state,
    online: action.payload
  };
}
