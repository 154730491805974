import { all, call, put, takeLatest } from 'redux-saga/effects';

import eventService from '../../services/EventService';
import { EVENTS_GET, setEvents, setEventsError } from '../actions/eventActions';

function* getEvents() {
  try {
    const { data } = yield call(eventService.getEvents);
    yield put(setEvents(data.results));
  } catch (e) {
    yield put(setEventsError());
  }
}

export default function* eventSaga() {
  yield all([takeLatest(EVENTS_GET, getEvents)]);
}
