import styled from '@emotion/styled';

import colors from '../constants/colors';
import variables from '../constants/variables';

export const StyledInfoServices = styled.div`
  display: flex;
  margin-bottom: ${variables.gutters.gut50};
  padding-right: ${variables.sizes.sidebarWidth};
  flex-wrap: wrap;
`;

export const InfoContainer = styled.div`
  height: 100%;
  background: ${colors.yankeesBlue};
  display: flex;
  flex-direction: column;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: ${variables.gutters.gut50} ${variables.gutters.gut30}
    ${variables.gutters.gut30};
`;
