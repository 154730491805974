import ApiService from './ApiService';

const ENDPOINTS = {
  EVENTS: '/events/'
};

class EventService extends ApiService {
  getEvents = () => this.apiClient.get(ENDPOINTS.EVENTS);
}

export default new EventService();
