import { css } from '@emotion/core';
import styled from '@emotion/styled';

import colors from '../constants/colors';
import fonts from '../constants/fonts';

export const splashScreenWrapper = css`
  .slick-slide {
    > div {
      background-color: ${colors.black};
      height: 100vh;
    }
  }
`;

export const splashTextWrapper = {
  bottom: '85px',
  left: '0',
  right: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const splashSliderImg = css`
  height: 100%;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
`;

export const SplashText = styled.p`
  position: fixed;
  bottom: 85px;
  font-size: ${fonts.size.h3m};
  color: ${colors.white};
  animation: pulse 4s infinite;
  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(0.9);
    }
  }
`;
