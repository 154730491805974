import QRCode from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DownloadAppContainer,
  QrCodeText
} from '../styles/components/DownloadAppStyle';
import {
  uFlex,
  uGapBottom30,
  uGapRight15,
  uGapTop30,
  uTextUppercase
} from '../styles/helpers';

const APP_STORE_LINK =
  'http://itunes.apple.com/us/app/Galerija-Belgrade/id1532716964';
const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.bggallery.bggallery';

const DownloadApp = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h4 css={[uTextUppercase, uGapBottom30, uGapTop30]}>
        {t('apps.download')}
      </h4>
      <div css={uFlex}>
        <DownloadAppContainer css={uGapRight15}>
          <QRCode bgColor="transparent" size={70} value={APP_STORE_LINK} />
          <QrCodeText>{t('apps.apple')}</QrCodeText>
        </DownloadAppContainer>
        <DownloadAppContainer>
          <QRCode bgColor="transparent" size={70} value={GOOGLE_PLAY_LINK} />
          <QrCodeText>{t('apps.android')}</QrCodeText>
        </DownloadAppContainer>
      </div>
    </div>
  );
};

export default DownloadApp;
