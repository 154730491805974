import PropTypes from 'prop-types';
import React from 'react';

const ModalFooter = ({ children }) => {
  return <div>{children}</div>;
};

ModalFooter.propTypes = {
  children: PropTypes.oneOf([PropTypes.object, PropTypes.node])
};

export default ModalFooter;
