import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ResidentTopList from '../resident/ResidentTopList';

const TrendingEventsList = ({ trendingEvents, onEventClick }) => {
  const { t } = useTranslation();

  return (
    <ResidentTopList
      list={trendingEvents}
      title={t('events.trendingEvents')}
      onClick={onEventClick}
    />
  );
};

TrendingEventsList.propTypes = {
  trendingEvents: PropTypes.array,
  onEventClick: PropTypes.func
};

export default TrendingEventsList;
