import { css } from '@emotion/core';
import styled from '@emotion/styled';

import colors from '../constants/colors';
import fonts from '../constants/fonts';
import variables from '../constants/variables';
import { uBkgWhite, uClrDark } from '../helpers';

export const SidebarWrapper = styled.div`
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  width: ${variables.sizes.sidebarWidth};
  ${uBkgWhite};
  border-top-left-radius: ${variables.radius.xxxl};
  border-bottom-left-radius: ${variables.radius.xxxl};
`;

export const iconStyle = css`
  width: ${variables.sizes.sidebarIcon};
`;

export const firstNavItemStyle = css`
  border-top-left-radius: ${variables.radius.xxxl};
`;

export const sidebarItemStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: ${variables.sizes.sidebarItem};
  padding: ${variables.gutters.gut5};
`;

export const sidebarItemText = css`
  font-size: ${fonts.size.xs};
`;

export const activeColor = css`
  color: ${colors.white};
`;

export const activeBcgColor = css`
  background-color: ${colors.orange};
`;

export const inactiveColor = css`
  ${uClrDark};
`;
