import PropTypes from 'prop-types';
import React from 'react';

import { RESIDENT_TYPES_LANG } from '../../../constants';
import {
  ListCard,
  ListCardDescription,
  ListCardImg,
  ListCardTextWrapper,
  ListCardTitle,
  ListCardType
} from '../../../styles/components/ListCardStyle';
import { uBkgPink, uClrLight } from '../../../styles/helpers';
import Image from '../Image/Image';

const getBackgroundByType = type => {
  if (Object.values(RESIDENT_TYPES_LANG.EVENT).includes(type)) {
    return [uBkgPink, uClrLight];
  }
};

const HomeListItem = ({
  slug,
  type,
  title,
  logo,
  description,
  onClick,
  showType = true
}) => (
  <ListCard
    css={getBackgroundByType(type)}
    onClick={() => onClick && onClick(slug)}
    type="button"
    showType={showType}
  >
    <ListCardTextWrapper>
      {showType && <ListCardType>{type}</ListCardType>}
      <ListCardTitle>{title}</ListCardTitle>
      <ListCardDescription>{description}</ListCardDescription>
    </ListCardTextWrapper>
    <Image css={ListCardImg} src={logo} />
  </ListCard>
);

HomeListItem.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  logo: PropTypes.string,
  slug: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  showType: PropTypes.bool
};

export default HomeListItem;
