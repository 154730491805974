import {
  addMinutes,
  closestTo,
  format,
  formatDistanceToNowStrict
} from 'date-fns';

export function dateToNowStrict(date, options) {
  if (date instanceof Date) {
    return formatDistanceToNowStrict(date, options);
  }

  const dateInstance = new Date(date);
  return formatDistanceToNowStrict(dateInstance, options);
}

export function findDateClosestToNow(dates, selector) {
  const now = new Date();

  const datesArray = selector ? extractAndCreateDate(dates, selector) : dates;
  return closestTo(now, datesArray);
}

export function adaptDate(date) {
  return addMinutes(date, date.getTimezoneOffset());
}

export function extractAndCreateDate(array, selector) {
  const now = new Date();
  return array.reduce((dates, item) => {
    if (adaptDate(new Date(item.end_date)) >= now) {
      dates.push(adaptDate(new Date(item[selector])));
    }
    return dates;
  }, []);
}

export function parseOpeningTime(time) {
  if (time) {
    const splitedTime = time.split(':');
    const hours = splitedTime[0];

    return `${hours}:${splitedTime[1]}`;
  }
  return '';
}

export function getFormattedDate(date, formatString = 'MMM dd, yyyy') {
  return date instanceof Date
    ? format(date, formatString)
    : format(new Date(date), formatString);
}
