/* eslint-disable no-shadow */
import 'react-simple-keyboard/build/css/index.css';
import '../SearchInput/Style.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Keyboard from 'react-simple-keyboard';

import FilterIcon from '../../assets/icons/filter.svg';
import { RESIDENT_TYPES } from '../../constants';
import { getCommonData } from '../../redux/actions/commonActions';
import {
  dineCategoriesGet,
  dineGet,
  dineSelectCategorySet,
  dineSelectedFloorSet,
  dinesGet,
  dinesInfoGet,
  filterDines,
  resetDines
} from '../../redux/actions/dineActions';
import { modalFilterContentSet } from '../../redux/actions/modalActions';
import {
  dineCategoriesSelector,
  dinesInfoSelector,
  dinesSelectedCategoriesSelector,
  dinesSelectedFloorsSelector,
  getDines,
  getHaveYouTriedDines,
  paginationDataSelector
} from '../../redux/selectors/dineSelectors';
import { floorsSelector } from '../../redux/selectors/floorSelectors';
import { isOnlineSelector } from '../../redux/selectors/networkSelector';
import colors from '../../styles/constants/colors';
import { Header, PageContainer } from '../../styles/container';
import { FilterStyled, FilterWrapper } from '../../styles/filter';
import {
  uBkgGreen,
  uGapRight15,
  uHeight100,
  uTextUppercase
} from '../../styles/helpers';
import ResidentListContainer from '../common/resident/ResidentListContainer';
import ResidentTopList from '../resident/ResidentTopList';

const Dines = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedLetter, setSelectedLetter] = useState('');
  const [searchValue] = useState('');

  const [keyboardVisibility, setKeyboardVisibility] = useState(false);
  const [input, setInput] = useState('');
  const [layout, setLayout] = useState('default');
  const keyboard = useRef();

  useEffect(() => {
    function clickHanlder(e) {
      if (
        !(e.target.nodeName === 'INPUT') &&
        !e.target.classList.contains('hg-button')
      ) {
        setKeyboardVisibility(false);
      }
    }

    window.addEventListener('click', clickHanlder);
    return window.removeEventListener('click', clickHanlder, true);
  }, []);

  const onChange = input => {
    setInput(input);
    console.log('Input changed', input);
  };

  const handleShift = () => {
    const newLayoutName = layout === 'default' ? 'shift' : 'default';
    setLayout(newLayoutName);
  };

  const onKeyPress = button => {
    console.log('Button pressed', button);

    if (button === '{bksp}') {
      setInput('');
    }

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === '{shift}' || button === '{lock}') handleShift();
  };

  const onChangeInput = event => {
    const input = event.target.value;
    setInput(input);
    keyboard.current.setInput(input);
  };

  const dines = useSelector(getDines);
  const info = useSelector(dinesInfoSelector);

  const floors = useSelector(floorsSelector);
  const categories = useSelector(dineCategoriesSelector);

  const selectedFloors = useSelector(dinesSelectedFloorsSelector);
  const selectedCategories = useSelector(dinesSelectedCategoriesSelector);
  const haveYouTried = useSelector(getHaveYouTriedDines);
  const online = useSelector(isOnlineSelector);

  const { next, count } = useSelector(paginationDataSelector);

  const setSelectedCategory = useCallback(
    data => dispatch(dineSelectCategorySet(data)),
    [dispatch]
  );
  const setSelectedFloors = useCallback(
    data => dispatch(dineSelectedFloorSet(data)),
    [dispatch]
  );

  useEffect(
    () => {
      dispatch(getCommonData());
      dispatch(dineCategoriesGet());
      dispatch(dinesInfoGet());

      return () => {
        dispatch(resetDines());
        setSelectedLetter('');
        setSelectedCategory([]);
        setSelectedFloors([]);
      };
    },
    [dispatch, online]
  );

  useEffect(
    () => {
      dispatch(
        filterDines({
          letter: selectedLetter,
          categories: selectedCategories.map(cat => cat.id),
          floors: selectedFloors.map(floor => floor.id),
          search: `*${searchValue}*`
        })
      );
    },
    [selectedLetter, online]
  );

  useEffect(
    () => {
      dispatch(
        filterDines({
          letter: input,
          categories: selectedCategories.map(cat => cat.id),
          floors: selectedFloors.map(floor => floor.id),
          search: `*${input}*`
        })
      );
    },
    [input, online]
  );

  const handleDineGet = useCallback(slug => dispatch(dineGet(slug)), [
    dispatch
  ]);

  const handleFilterClick = () =>
    dispatch(
      modalFilterContentSet({
        floors,
        categories,
        selectedFloors,
        selectedCategories,
        selectedLetter,
        setSelectedFloors,
        setSelectedCategory,
        filterAction: filterDines
      })
    );

  const isFloorOrCategoryToggled = () =>
    selectedCategories.length || selectedFloors.length;

  const handleLoadMore = () => {
    dispatch(
      dinesGet({
        letter: selectedLetter,
        categories: selectedCategories.map(cat => cat.id),
        floors: selectedFloors.map(floor => floor.id),
        search: `*${searchValue}*`
      })
    );
  };

  return (
    <div css={uHeight100}>
      <Header css={uBkgGreen}>
        <h1 css={uTextUppercase}>{t('dines.foodDrink')}</h1>
      </Header>
      <PageContainer>
        <ResidentTopList
          title={t('dines.topRestaurants')}
          list={haveYouTried}
          onClick={handleDineGet}
        />
        {online && (
          <div>
            <div style={{ flex: 1, width: '90%', marginBottom: 20 }}>
              <input
                style={{
                  width: '100%',
                  height: 60,
                  fontSize: 22,
                  paddingLeft: 15
                }}
                className="input_search"
                onFocus={() => {
                  setKeyboardVisibility(true);
                }}
                value={input}
                placeholder={t('search.search_totem')}
                onChange={onChangeInput}
              />
              {keyboardVisibility && (
                <Keyboard
                  style={{ width: '100%' }}
                  // eslint-disable-next-line no-return-assign
                  keyboardRef={r => (keyboard.current = r)}
                  layoutName={layout}
                  onChange={onChange}
                  onKeyPress={onKeyPress}
                />
              )}
            </div>

            <FilterWrapper>
              <FilterStyled
                css={uGapRight15}
                onClick={handleFilterClick}
                type="button"
                isSelected={isFloorOrCategoryToggled()}
              >
                <FilterIcon
                  fill={
                    isFloorOrCategoryToggled()
                      ? colors.white
                      : colors.yankeesBlue
                  }
                />
              </FilterStyled>
            </FilterWrapper>
          </div>
        )}

        <ResidentListContainer
          list={dines}
          count={count}
          handleItemClick={handleDineGet}
          info={info}
          type={RESIDENT_TYPES.DINE}
          selectedLetter={selectedLetter}
          setSelectedLetter={setSelectedLetter}
          handleLoadMore={handleLoadMore}
          hasMore={!!next}
        />
      </PageContainer>
    </div>
  );
};

Dines.propTypes = {};

export default Dines;
