import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  NotificationText,
  NotificationWrapper
} from '../../styles/components/HomeStyle';

const AlertNotification = ({ alertNotification }) => {
  const { t } = useTranslation();
  const { text } = alertNotification;
  return (
    <NotificationWrapper>
      <NotificationText>
        {t('alertNotification.text', { text })}
      </NotificationText>
    </NotificationWrapper>
  );
};

AlertNotification.propTypes = {
  alertNotification: PropTypes.shape({
    logo: PropTypes.string,
    text: PropTypes.string
  })
};

export default AlertNotification;
