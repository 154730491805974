import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../assets/images/loader.svg';
import { singleOfferModalSet } from '../../redux/actions/modalActions';
import { getOffers } from '../../redux/actions/offerActions';
import {
  offerHasMoreSelector,
  offerSelector
} from '../../redux/selectors/offerSelectors';
import { ListCardContainerHalf } from '../../styles/components/ListCardStyle';
import { Header, PageContainer, PageTopPadding } from '../../styles/container';
import { uBkgOrange, uTextUppercase } from '../../styles/helpers';
import CardItem from '../common/CardItem';

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const loaderStyles = css`
  width: 4rem;
  height: 4rem;
`;

const Offers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const offers = useSelector(offerSelector);
  const hasMore = useSelector(offerHasMoreSelector);

  useEffect(
    () => {
      dispatch(getOffers({ offset: 0 }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchOffers = () => {
    dispatch(getOffers({ offset: offers.length }));
  };

  const handleOfferClick = offer => {
    dispatch(singleOfferModalSet(offer));
  };

  return (
    <>
      <Header css={uBkgOrange}>
        <h1 css={uTextUppercase}>{t('offers.offers')}</h1>
      </Header>
      <PageContainer css={[PageTopPadding]}>
        <InfiniteScroll
          dataLength={offers.length}
          next={fetchOffers}
          hasMore={hasMore}
          scrollThreshold="500px"
          pullDownToRefresh={false}
          loader={
            <LoaderContainer>
              <Loader css={loaderStyles} />
            </LoaderContainer>
          }
        >
          <ListCardContainerHalf>
            {offers.map(offer => (
              <CardItem
                onClick={() => handleOfferClick(offer)}
                title={offer.offer_title}
                description={offer.offer_description}
                image={offer.mobile_image}
              />
            ))}
          </ListCardContainerHalf>
        </InfiniteScroll>
      </PageContainer>
    </>
  );
};

export default Offers;
