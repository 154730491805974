export function initMacAddress() {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  const params = new URLSearchParams(window.location.search);
  const macAddress = params.get('mac_address');

  if (!macAddress) {
    throw new Error('Cannot initialize kiosk');
  }

  localStorage.setItem('macAddress', macAddress);
}

export function getMacAddress() {
  return localStorage.getItem('macAddress');
}
