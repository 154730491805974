import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import {
  FilterButton,
  TabFilterWrap
} from '../../styles/components/FilterStyle';
import { filterListByKey } from '../../utils/events';

const isEventSelected = (label, activeFilter) =>
  activeFilter && activeFilter.label === label;

const EventsFilter = ({ filters, onClick, activeFilter, data }) => {
  const renderFilter = filter => (
    <FilterButton
      key={filter}
      onClick={() => onClick(filters[filter])}
      isSelected={isEventSelected(filters[filter].label, activeFilter)}
    >
      <p>{filters[filter].label}</p>
    </FilterButton>
  );

  const filteredFilters = useMemo(
    () =>
      Object.keys(filters).filter(
        key => filterListByKey(data, filters[key].keysToFilter).length
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  useEffect(
    () => {
      onClick(filters[filteredFilters[0]]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredFilters]
  );

  return (
    <TabFilterWrap>
      {filteredFilters.map(filter => renderFilter(filter))}
    </TabFilterWrap>
  );
};

EventsFilter.propTypes = {
  filters: PropTypes.object,
  onClick: PropTypes.func,
  activeFilter: PropTypes.object,
  data: PropTypes.array
};

export default EventsFilter;
