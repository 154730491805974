import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';

import i18n from '../../i18n';
import { ModalText } from '../../styles/components/ModalStyle';
import { uFlex } from '../../styles/helpers';
import { parseOpeningTime } from '../../util/dateUtil';

const openingDaysText = css`
  flex: 1;
`;

const openingHoursText = css`
  flex: 6;
`;

const formatDay = string => {
  return `${string.charAt(0).toUpperCase()}${string.substr(1, 2)}`;
};

const getResidentOpeningDays = (startDay, endDay) => {
  if (startDay === endDay) {
    return formatDay(startDay);
  }
  return `${formatDay(startDay)} - ${formatDay(endDay)}`;
};

const getResidentOpeningHours = (fromHour, toHour, closed) => {
  if (closed) {
    return i18n.t('resident.closed');
  }
  return `${parseOpeningTime(fromHour)} - ${parseOpeningTime(toHour)}`;
};

const getOpeningHours = openingHours => {
  if (openingHours) {
    return openingHours.map(openingHour => (
      <div key={openingHour.id} css={uFlex}>
        <ModalText css={openingDaysText}>
          {getResidentOpeningDays(
            openingHour.weekday_from,
            openingHour.weekday_to
          )}
        </ModalText>
        <ModalText css={openingHoursText}>
          {getResidentOpeningHours(
            openingHour.from_hour,
            openingHour.to_hour,
            openingHour.closed
          )}
        </ModalText>
      </div>
    ));
  }
};

const ResidentOpeningHours = ({ openingHours }) => (
  <ul>{getOpeningHours(openingHours)}</ul>
);

ResidentOpeningHours.propTypes = {
  openingHours: PropTypes.array
};

export default ResidentOpeningHours;
