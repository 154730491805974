import { EVENT_DATE_KEYS } from '../constants/events';
import i18n from '../i18n';
import { adaptDate } from '../util/dateUtil';
import {
  calculateDaysTillEndOfWeek,
  daysDifference,
  isWithinRange,
  nextMonthConditions,
  parseDateWithYear,
  thisMonthCondition
} from './date';

export const incrementEventsCount = (events, eventId, increment) =>
  events.map(
    event =>
      event.id === eventId
        ? {
          ...event,
          interested_counter: event.interested_counter + increment
        }
        : event
  );

const getDaysDifference = ({ start_date }) =>
  daysDifference(new Date(start_date), new Date());

const popEventsList = (events, condition) => {
  let popedEvents = [];

  while (events.length && condition(events[0])) {
    const popedElement = events.shift();
    popedEvents = [...popedEvents, popedElement];
  }

  return popedEvents;
};

export const eventsTimelineParser = events => {
  const daysTillEndOfTheWeek = calculateDaysTillEndOfWeek();
  const todayEvents = popEventsList(
    events,
    event => getDaysDifference(event) < 1
  );

  const tomorrowEvents = popEventsList(
    events,
    event => getDaysDifference(event) < 2
  );
  // days left till end of the week - 2 so we dont count in saturday and sunday
  const thisWeekEvents = popEventsList(
    events,
    event => getDaysDifference(event) <= daysTillEndOfTheWeek - 2
  );
  const thisWeekendEvents = popEventsList(
    events,
    event => getDaysDifference(event) <= daysTillEndOfTheWeek
  );
  const nextWeekEvents = popEventsList(
    events,
    event => getDaysDifference(event) <= daysTillEndOfTheWeek + 7
  );
  const thisMonthEvents = popEventsList(events, event =>
    thisMonthCondition(new Date(event.start_date))
  );
  const nextMonthEvents = popEventsList(events, event =>
    nextMonthConditions(new Date(event.start_date))
  );

  const restEvents = events;

  return [
    {
      events: todayEvents,
      time: i18n.t('dateMoments.today'),
      key: EVENT_DATE_KEYS.TODAY
    },
    {
      events: tomorrowEvents,
      time: i18n.t('dateMoments.tomorrow'),
      key: EVENT_DATE_KEYS.TOMORROW
    },
    {
      events: thisWeekEvents,
      time: i18n.t('dateMoments.thisWeek'),
      key: EVENT_DATE_KEYS.THIS_WEEK
    },
    {
      events: thisWeekendEvents,
      time: i18n.t('dateMoments.thisWeekend'),
      key: EVENT_DATE_KEYS.THIS_WEEKED
    },
    {
      events: nextWeekEvents,
      time: i18n.t('dateMoments.nextWeek'),
      key: EVENT_DATE_KEYS.NEXT_WEEK
    },
    {
      events: thisMonthEvents,
      time: i18n.t('dateMoments.thisMonth'),
      key: EVENT_DATE_KEYS.THIS_MONTH
    },
    {
      events: nextMonthEvents,
      time: i18n.t('dateMoments.nextMonth'),
      key: EVENT_DATE_KEYS.NEXT_MONTH
    },
    {
      events: restEvents,
      time: i18n.t('dateMoments.soon'),
      key: EVENT_DATE_KEYS.SOON
    }
  ];
};

const isEventTodayOrTomorrow = date => {
  const eventDate = new Date(date);
  const now = new Date();
  const difference = daysDifference(eventDate, now);

  if (difference < 1) {
    return i18n.t('dateMoments.today');
  }
  if (difference < 2) {
    return i18n.t('dateMoments.tomorrow');
  }
  return parseDateWithYear(date);
};

export const groupEventsByDate = events => {
  const now = new Date();
  const sortedByDate = events.reduce((groups, event) => {
    const eventDate = new Date(event.start_date);
    const eventStartedDaysAgo = daysDifference(eventDate, now);

    const date = eventStartedDaysAgo < 1 ? now : event.start_date.split('T')[0];

    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(event);
    return groups;
  }, {});

  return Object.keys(sortedByDate).map(key => ({
    events: sortedByDate[key],
    time: isEventTodayOrTomorrow(key),
    key
  }));
};

export function isHappeningNow({ start_date, end_date }) {
  const now = new Date();

  const start =
    start_date instanceof Date
      ? adaptDate(start_date)
      : adaptDate(new Date(start_date));
  const end =
    end_date instanceof Date
      ? adaptDate(end_date)
      : adaptDate(new Date(end_date));
  return isWithinRange(now, { start, end });
}

export const filterListByKey = (list, keys) => {
  const eventsList = [];
  list.forEach(listElement => {
    if (keys.some(key => key === listElement.key)) {
      eventsList.push(listElement.events);
    }
  });
  return eventsList.flat();
};
