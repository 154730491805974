import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getCommonData } from '../../../redux/actions/commonActions';
import { singleOfferModalSet } from '../../../redux/actions/modalActions';
import { homeOffersSelector } from '../../../redux/selectors/offerSelectors';
import { seeAllLink } from '../../../styles/buttons';
import HomeList from './HomeList';

const OffersList = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(getCommonData());
    },
    [dispatch]
  );

  const offers = useSelector(homeOffersSelector);

  const formList = () =>
    offers.map(offer => ({
      type: t('common.offer'),
      slug: offer.offer_code,
      logo: offer.mobile_image,
      title: offer.brand_name,
      description: offer.offer_title
    }));

  const handleOfferClick = offerCode => {
    const offer = offers.find(({ offer_code }) => offer_code === offerCode);
    dispatch(singleOfferModalSet(offer));
  };

  return (
    <HomeList
      key="common.offer"
      list={formList()}
      onClick={handleOfferClick}
      title="common.offers"
      showType={false}
      renderLink={() => (
        <Link css={seeAllLink} to="offers">
          {t('common.seeAll')}
        </Link>
      )}
    />
  );
};

export default OffersList;
