import { OFFER_LIMIT } from '../constants/offers';
import { isVideo } from '../utils';
import ApiService from './ApiService';

const ENDPOINTS = {
  COMMON: '/common/',
  ALERT_NOTIFICATIONS: '/alert_notification/',
  SPLASH: '/kiosk/splash/',
  GET_OFFERS: '/loyalty/offers/general/'
};

class CommonService extends ApiService {
  getCommonData = () => this.apiClient.get(ENDPOINTS.COMMON);

  getAlertNotifications = () =>
    this.apiClient.get(ENDPOINTS.ALERT_NOTIFICATIONS);

  getSplash = () => this.apiClient.get(ENDPOINTS.SPLASH);

  getOffers = offset =>
    this.apiClient.get(ENDPOINTS.GET_OFFERS, {
      params: { offset, limit: OFFER_LIMIT }
    });

  getBlobs = splashes =>
    splashes.reduce(async (acc, { image }) => {
      const accumulator = await acc;
      if (isVideo(image)) {
        const response = await fetch(image);
        const blob = await response.blob();
        return [...accumulator, blob];
      }
      return [...accumulator, ''];
    }, []);
}

export default new CommonService();
