import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { RESIDENT_TYPES } from '../../constants';
import categoryService from '../../services/CategoryService';
import shopService from '../../services/ShopService';
import { singleResidentModalSet } from '../actions/modalActions';
import {
  appendShops,
  setShopError,
  setShops,
  SHOP_CATEGORIES_GET,
  SHOP_GET,
  shopCategoriesSet,
  SHOPS_FILTER,
  SHOPS_GET,
  SHOPS_INFO_GET,
  shopsInfoSet,
  shopsTopSet,
  TOP_SHOPS_GET
} from '../actions/shopActions';
import { paginationDataSelector } from '../selectors/shopSelector';

function* getShops({ payload }) {
  try {
    const { next, count } = yield select(paginationDataSelector);
    const { data } = yield call(
      shopService.getShops,
      {
        title__wildcard: `${payload.letter}*`,
        ...payload
      },
      next
    );
    yield put(next || count ? appendShops(data) : setShops(data));
  } catch (e) {
    yield put(setShopError());
  }
}

function* getShop({ payload }) {
  try {
    const { data } = yield call(shopService.getShop, payload);
    yield put(singleResidentModalSet(data, RESIDENT_TYPES.SHOP));
  } catch (e) {
    yield put(setShopError());
  }
}

export function* shopCategoriesGet() {
  try {
    const { data } = yield call(categoryService.getShopsCategories);

    yield put(shopCategoriesSet(data.categories));
  } catch (error) {
    yield put(setShopError(true));
  }
}

function* handleGetShopsInfo() {
  try {
    const { data } = yield call(shopService.getInfo);
    yield put(shopsInfoSet(data));
  } catch (e) {
    yield put(setShopError());
  }
}

function* handleGetTopShops() {
  try {
    const { data } = yield call(shopService.getTopShops);
    yield put(shopsTopSet(data.results));
  } catch (e) {
    yield put(setShopError());
  }
}

function* handleFilterShops({ payload }) {
  try {
    const { data } = yield call(shopService.getShops, {
      title__wildcard: `${payload.letter}*`,
      ...payload
    });
    yield put(setShops(data));
  } catch (e) {
    yield put(setShopError());
  }
}

export default function* shopSaga() {
  yield all([
    takeLatest(SHOPS_GET, getShops),
    takeLatest(SHOP_GET, getShop),
    takeLatest(SHOP_CATEGORIES_GET, shopCategoriesGet),
    takeLatest(SHOPS_INFO_GET, handleGetShopsInfo),
    takeLatest(TOP_SHOPS_GET, handleGetTopShops),
    takeLatest(SHOPS_FILTER, handleFilterShops)
  ]);
}
