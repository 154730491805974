import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect } from 'react';

import MapService from '../../../services/MapService';
import { mapIframe } from '../../../styles/components/MapScreen';

const READY_ACTION = 'ready';
const GO_TO_STORE_ACTION = 'goToStore';
const CHANGE_FLOOR_ACTION = 'changeFloor';

const MapIframe = forwardRef(({ onReady, goToResident, changeFloor }, ref) => {
  const onMessage = useCallback(
    nativeEvent => {
      if (nativeEvent.data === READY_ACTION) {
        onReady();
        return;
      }
      let event = nativeEvent.data;
      if (typeof nativeEvent.data === 'string')
        event = JSON.parse(nativeEvent.data);

      if (event?.command === GO_TO_STORE_ACTION) {
        goToResident(event.data);
      }

      if (event?.command === CHANGE_FLOOR_ACTION) {
        changeFloor(event.data.floorId);
      }
    },
    [changeFloor, goToResident, onReady]
  );

  useEffect(
    () => {
      window.addEventListener('message', onMessage, false);

      return () => window.removeEventListener('message', onMessage);
    },
    [onMessage]
  );

  return (
    <iframe ref={ref} css={mapIframe} src={MapService.getUrl()} title="MAP" />
  );
});

MapIframe.propTypes = {
  onReady: PropTypes.func,
  goToResident: PropTypes.func,
  changeFloor: PropTypes.func
};

MapIframe.displayName = 'MapIframe';

export default MapIframe;
