export const parseDistrictAndFloors = (districts, floors) => {
  if (!districts || !districts.length) {
    return parseFloors(floors);
  }

  return `${parseDistricts(districts)} - ${parseFloors(floors)}`;
};

const parseDistricts = districts =>
  districts && districts.length ? districts[0].name : '';

const parseFloors = floors =>
  floors && floors.length ? floors.map(({ name }) => name).join(', ') : '';
