import styled from '@emotion/styled';

import colors from '../constants/colors';
import fonts from '../constants/fonts';
import variables from '../constants/variables';
import { uClrDark } from '../helpers';

export const DownloadAppContainer = styled.div`
  width: ${variables.sizes.downloadAppWidth};
  height: ${variables.sizes.downloadAppHeight};
  background-color: ${colors.lightPeriwinkle};
  ${uClrDark};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  border-radius: ${variables.radius.base};
`;

export const QrCodeText = styled.p`
  font-family: ${fonts.family.bold};
  font-size: ${fonts.size.sm};
  margin-top: ${variables.gutters.gut10};
`;
