import { css } from '@emotion/core';
import styled from '@emotion/styled';

import colors from '../constants/colors';
import fonts from '../constants/fonts';
import variables from '../constants/variables';
import { uBkgLight, uClrDark } from '../helpers';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.black50};
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  flex-direction: column;
  ${props => props.isBlured && 'backdrop-filter: blur(5px);'};
`;

export const ModalContainer = styled.div`
  width: ${props => props.width || variables.sizes.modalWidth};
  max-height: ${variables.sizes.modalHeight};
  overflow-y: auto;
`;

export const ModalTitleWrapper = styled.div`
  padding: ${variables.gutters.gut15} ${variables.gutters.gut30};
  background-color: ${props => props.color};
`;

export const ModalBody = styled.div`
  background-color: ${colors.lightPeriwinkle};
  ${uClrDark};
  padding: ${variables.gutters.gut30};
`;

export const ModalTopRadius = css`
  border-radius: ${variables.radius.md} ${variables.radius.md} 0 0;
`;

export const ModalBodyGap = css`
  padding-top: 0;
  padding-bottom: calc(${variables.gutters.gut30} * 3);
  margin-top: -1px;
`;

export const ModalTitle = styled.h4`
  font-size: ${fonts.size.sm};
  text-transform: uppercase;
  margin-bottom: ${variables.gutters.gut15};
`;

export const ModalText = styled.p`
  font-size: ${fonts.size.sm};
`;

export const ModalTextWithLineBreak = styled.p`
  font-size: ${fonts.size.sm};
  white-space: pre-wrap;
  text-align: justify;
`;

export const IconWrapper = styled.div`
  width: ${variables.sizes.locationIconWidth};
  height: ${variables.sizes.locationIconHeight};
  margin-right: ${variables.gutters.gut10};
`;

export const ModalBtnWrapper = styled.div`
  padding: ${variables.gutters.gut15} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${uBkgLight};
  width: 100%;
  border-radius: 0 0 ${variables.radius.md} ${variables.radius.md};
`;

export const modalBtn = css`
  width: ${variables.sizes.modalBtnWidth};
`;

export const sliderImg = css`
  width: auto;
  object-fit: cover;
  height: ${variables.sizes.sliderImg};
  margin-bottom: -${variables.gutters.gut5};
`;

export const fullWidth = css`
  width: 100%;
`;

export const halfWidth = css`
  width: 50%;
`;

export const CloseIconWrapper = styled.div`
  width: ${variables.sizes.closeIconWrapper};
  height: ${variables.sizes.closeIconWrapper};
  border-radius: ${variables.sizes.closeIconWrapper};
  background-color: ${colors.lightPeriwinkle};
  fill: ${colors.yankeesBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${variables.gutters.gut50};

  > svg {
    width: unset;
    height: unset;
  }
`;

export const ModalFilterTitle = styled.h3`
  color: ${colors.white};
  text-transform: uppercase;
  text-align: center;
  font-family: ${fonts.family.regular};
`;

export const ModalFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ModalFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalFilterBtn = css`
  font-size: ${fonts.size.h3m};
  color: ${colors.white};
  font-family: ${fonts.family.bold};
  text-transform: capitalize;
  background-color: transparent;
  border: none;
`;

export const modalImgStyle = css`
  width: 100%;
  height: ${variables.sizes.modalImgWidth};
  object-fit: cover;
  margin-bottom: -${variables.gutters.gut5};
`;

export const SearchInput = styled.input`
  width: 100%;
  height: ${variables.sizes.searchInputHeight};
  background-color: ${colors.whiteDirty};
  border: none;
  background-image: url("/images/search-gray-icon.svg");
  background-repeat: no-repeat;
  background-size: ${variables.sizes.searchIconHeight};
  background-position-y: center;
  background-position-x: calc(100% - ${variables.gutters.gut25});
  color: ${colors.black50};
  border-radius: 3px;
  font-size: 1.3333333333333333rem;
  padding: 0 ${variables.gutters.gut20};

  &:focus {
    outline: none;
  }
`;

export const SearchModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
