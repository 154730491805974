import PropTypes from 'prop-types';
import React from 'react';

const NavTitle = ({ hideTitle, title, style }) => {
  return !hideTitle ? <div css={style}>{title}</div> : null;
};

NavTitle.propTypes = {
  hideTitle: PropTypes.bool,
  title: PropTypes.string,
  style: PropTypes.any
};

export default NavTitle;
