import {
  SHOP_CATEGORIES_SET,
  SHOP_SELECT_CATEGORY_SET,
  SHOP_SELECT_FLOOR_SET,
  SHOPS_APPEND,
  SHOPS_INFO_SET,
  SHOPS_RESET,
  SHOPS_SET,
  SHOPS_TOP_SET
} from '../actions/shopActions';
import createReducer from '../util';

const initialState = {
  shops: [],
  next: null,
  count: 0,
  searchInfo: {},
  selectedFloors: [],
  selectedCategories: [],
  shopCategories: [],
  topShops: []
};

export default createReducer(
  {
    [SHOPS_SET]: setShops,
    [SHOPS_INFO_SET]: setShopsInfo,
    [SHOP_SELECT_CATEGORY_SET]: setSelectedCategories,
    [SHOP_SELECT_FLOOR_SET]: setSelectedFloors,
    [SHOP_CATEGORIES_SET]: setShopCategories,
    [SHOPS_TOP_SET]: shopsTopSet,
    [SHOPS_APPEND]: shopsAppend,
    [SHOPS_RESET]: shopsReset
  },
  initialState
);

function shopsTopSet(state, action) {
  return {
    ...state,
    topShops: action.payload
  };
}

function setShops(state, action) {
  return {
    ...state,
    count: action.payload.count,
    next: action.payload.next,
    shops: action.payload.results
  };
}

function setShopsInfo(state, action) {
  const { count, characters } = action.payload;
  const searchInfo = { total: count, letters: characters };

  return {
    ...state,
    searchInfo
  };
}

function setSelectedFloors(state, action) {
  return {
    ...state,
    selectedFloors: action.payload
  };
}

function setSelectedCategories(state, action) {
  return {
    ...state,
    selectedCategories: action.payload
  };
}

function setShopCategories(state, action) {
  return {
    ...state,
    shopCategories: action.payload
  };
}

function shopsAppend(state, action) {
  return {
    ...state,
    count: action.payload.count,
    next: action.payload.next,
    shops: [...state.shops, ...action.payload.results]
  };
}

function shopsReset() {
  return { ...initialState };
}
