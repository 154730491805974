/* eslint-disable no-shadow */
import '../SearchInput/Style.css';
import 'react-simple-keyboard/build/css/index.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Keyboard from 'react-simple-keyboard';

import FilterIcon from '../../assets/icons/filter.svg';
import { RESIDENT_TYPES } from '../../constants';
import { modalFilterContentSet } from '../../redux/actions/modalActions';
import {
  filterShops,
  getShop,
  getShops,
  getTopShops,
  resetShops,
  shopCategoriesGet,
  shopSelectCategorySet,
  shopSelectedFloorSet,
  shopsInfoGet
} from '../../redux/actions/shopActions';
import { floorsSelector } from '../../redux/selectors/floorSelectors';
import { isOnlineSelector } from '../../redux/selectors/networkSelector';
import {
  paginationDataSelector,
  shopCategoriesSelector,
  shopsInfoSelector,
  shopsSelectedCategoriesSelector,
  shopsSelectedFloorsSelector,
  shopsSelector,
  topShopsSelector
} from '../../redux/selectors/shopSelector';
import colors from '../../styles/constants/colors';
import { Header, PageContainer } from '../../styles/container';
import { FilterStyled, FilterWrapper } from '../../styles/filter';
import { uBkgPurple, uGapRight15, uTextUppercase } from '../../styles/helpers';
import ResidentListContainer from '../common/resident/ResidentListContainer';
import ResidentTopList from '../resident/ResidentTopList';

const Shops = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedLetter, setSelectedLetter] = useState('');
  const [searchValue] = useState('');
  const shops = useSelector(shopsSelector);
  const info = useSelector(shopsInfoSelector);
  const floors = useSelector(floorsSelector);
  const categories = useSelector(shopCategoriesSelector);
  const online = useSelector(isOnlineSelector);
  const selectedFloors = useSelector(shopsSelectedFloorsSelector);
  const selectedCategories = useSelector(shopsSelectedCategoriesSelector);
  const topShops = useSelector(topShopsSelector);
  const { next, count } = useSelector(paginationDataSelector);

  const setSelectedCategory = useCallback(
    data => dispatch(shopSelectCategorySet(data)),
    [dispatch]
  );
  const setSelectedFloors = useCallback(
    data => dispatch(shopSelectedFloorSet(data)),
    [dispatch]
  );

  const handleLoadMore = () => {
    dispatch(
      getShops({
        letter: selectedLetter,
        categories: selectedCategories.map(cat => cat.id),
        floors: selectedFloors.map(floor => floor.id),
        search: `*${searchValue}*`
      })
    );
  };

  const [keyboardVisibility, setKeyboardVisibility] = useState(false);
  const [input, setInput] = useState('');
  const [layout, setLayout] = useState('default');
  const keyboard = React.useRef();

  useEffect(() => {
    function clickHanlder(e) {
      if (
        !(e.target.nodeName === 'INPUT') &&
        !e.target.classList.contains('hg-button')
      ) {
        setKeyboardVisibility(false);
      }
    }

    window.addEventListener('click', clickHanlder);
    return window.removeEventListener('click', clickHanlder, true);
  }, []);

  // eslint-disable-next-line no-shadow
  const onChange = input => {
    setInput(input);
  };

  const handleShift = () => {
    const newLayoutName = layout === 'default' ? 'shift' : 'default';
    setLayout(newLayoutName);
  };

  const onKeyPress = button => {
    if (button === '{bksp}') {
      setInput('');
    }
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === '{shift}' || button === '{lock}') handleShift();
  };

  const onChangeInput = event => {
    const input = input || event.target.value;
    setInput(input);
    keyboard.current.setInput(input);
  };

  useEffect(
    () => {
      dispatch(getTopShops());
      dispatch(shopCategoriesGet());
      dispatch(shopsInfoGet());

      return () => {
        dispatch(resetShops());
        setSelectedLetter('');
        setSelectedCategory([]);
        setSelectedFloors([]);
      };
    },
    [dispatch, online]
  );

  useEffect(
    () => {
      dispatch(
        filterShops({
          letter: selectedLetter,
          categories: selectedCategories.map(cat => cat.id),
          floors: selectedFloors.map(floor => floor.id),
          search: `*${searchValue}*`
        })
      );
    },
    [selectedLetter, online]
  );

  useEffect(
    () => {
      dispatch(
        filterShops({
          letter: input,
          categories: selectedCategories.map(cat => cat.id),
          floors: selectedFloors.map(floor => floor.id),
          search: `*${input}*`
        })
      );
    },
    [input, online]
  );

  const handleShopClick = slug => {
    dispatch(getShop(slug));
  };

  const handleFilterClick = () =>
    dispatch(
      modalFilterContentSet({
        floors,
        categories,
        selectedFloors,
        selectedCategories,
        selectedLetter,
        setSelectedFloors,
        setSelectedCategory,
        filterAction: filterShops
      })
    );

  const isFloorOrCategoryToggled = () =>
    selectedCategories.length || selectedFloors.length;

  return (
    <div>
      <Header css={uBkgPurple}>
        <h1 css={uTextUppercase}>{t('shops.shops')}</h1>
      </Header>
      <PageContainer>
        <ResidentTopList
          list={topShops}
          onClick={handleShopClick}
          title={t('shops.topShops')}
        />
        {online && (
          <div>
            <div style={{ flex: 1, width: '90%', marginBottom: 20 }}>
              <input
                style={{
                  width: '100%',
                  height: 60,
                  fontSize: 22,
                  paddingLeft: 15
                }}
                className="input_search"
                onFocus={() => {
                  setKeyboardVisibility(true);
                }}
                value={input}
                placeholder={t('search.search_totem')}
                onChange={onChangeInput}
              />
              {keyboardVisibility && (
                <Keyboard
                  style={{ width: '100%' }}
                  // eslint-disable-next-line no-return-assign
                  keyboardRef={r => (keyboard.current = r)}
                  layoutName={layout}
                  onChange={onChange}
                  onKeyPress={onKeyPress}
                />
              )}
            </div>
            <FilterWrapper>
              <FilterStyled
                css={uGapRight15}
                onClick={handleFilterClick}
                type="button"
                isSelected={isFloorOrCategoryToggled()}
              >
                <FilterIcon
                  fill={
                    isFloorOrCategoryToggled() ? colors.white : colors.black
                  }
                />
              </FilterStyled>
            </FilterWrapper>
          </div>
        )}
        <ResidentListContainer
          list={shops}
          info={info}
          count={count}
          handleItemClick={handleShopClick}
          type={RESIDENT_TYPES.SHOP}
          selectedLetter={selectedLetter}
          setSelectedLetter={setSelectedLetter}
          handleLoadMore={handleLoadMore}
          hasMore={!!next}
        />
      </PageContainer>
    </div>
  );
};

export default Shops;
