import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const Video = forwardRef(({ src, ...props }, ref) => {
  return (
    <video
      src={src}
      controls={false}
      muted
      ref={ref}
      preload="auto"
      {...props}
    />
  );
});

Video.propTypes = {
  src: PropTypes.string
};

export default Video;
