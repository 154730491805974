import { createSelector } from 'reselect';

const dineSelector = state => state.dine;

export const getDines = createSelector(dineSelector, state => state.dines);

export const getHaveYouTriedDines = createSelector(
  dineSelector,
  state => state.haveYouTried
);

export const dinesInfoSelector = createSelector(
  dineSelector,
  state => state.searchInfo
);

export const dinesSelectedCategoriesSelector = createSelector(
  dineSelector,
  state => state.selectedCategories
);

export const dinesSelectedFloorsSelector = createSelector(
  dineSelector,
  state => state.selectedFloors
);

export const dineCategoriesSelector = createSelector(
  dineSelector,
  state => state.dineCategories
);

export const paginationDataSelector = createSelector(dineSelector, state => ({
  count: state.count,
  next: state.next
}));
