import { css } from '@emotion/core';
import styled from '@emotion/styled';

import colors from '../constants/colors';
import fonts from '../constants/fonts';
import variables from '../constants/variables';
import { uBkgLight } from '../helpers';

export const ItemWrapper = styled.button`
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  border-radius: ${variables.radius.md};
  overflow: hidden;
  ${uBkgLight};
`;

export const imageStyle = css`
  width: ${variables.sizes.listLogoWidth};
  height: ${variables.sizes.listLogoHeight};
  padding: ${variables.gutters.gut10};
`;

export const regularImgStyle = css`
  background-color: ${colors.white};

  > svg {
    fill: ${colors.black};
  }
`;

export const ItemContainer = styled.div`
  padding: ${variables.gutters.gut15};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ItemTitle = styled.p`
  font-size: ${fonts.size.sm};
  font-family: ${fonts.family.bold};
  text-transform: uppercase;
  margin-bottom: ${variables.gutters.gut5};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${variables.sizes.itemText};
`;

export const ItemText = styled.p`
  font-size: ${fonts.size.xs};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${variables.sizes.itemText};
  margin-bottom: ${variables.gutters.gut5};
`;

export const itemBtnStyle = css`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid;
  text-transform: uppercase;
`;

export const regularBtnStyle = css`
  color: ${colors.black};
`;

export const luxuryBtnStyle = css`
  color: ${colors.white};
`;
