import { css } from '@emotion/core';

import colors from './constants/colors';
import fonts from './constants/fonts';
import variables from './constants/variables';
import { uBkgDark, uBkgLight, uClrDark, uClrLight } from './helpers';

export const btnBase = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${variables.gutters.gut15};
  ${uClrLight};
  text-align: center;
  font-size: ${fonts.size.sm};
  font-family: ${fonts.family.bold};
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  border: none;
  ${uBkgDark};
  border-radius: 3px;

  &:focus,
  &:active {
    outline: none;
    background-color: ${colors.white10};
  }

  &[disabled] {
    color: ${colors.gray2};
    cursor: default;
  }
`;

export const btnBaseLight = css`
  ${btnBase} ${uBkgLight};
  ${uClrDark};
`;

export const btnBig = css`
  width: ${variables.sizes.btnBigWidth};
  padding: ${variables.gutters.gut20};
  border-radius: ${variables.radius.base};
`;

export const btnWhite = css`
  color: ${colors.black};
  background-color: ${colors.white};
`;

export const btnLight = css`
  ${uClrDark} ${uBkgLight};
`;

export const btnOrange = css`
  ${uClrLight};
  background-color: ${colors.orange};

  &:active,
  &:focus {
    outline: none;
  }
`;

export const btnSm = css`
  padding: ${variables.gutters.gut10};
  font-size: ${fonts.size.xs};
`;

export const btnRound = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border-radius: 50%;
`;

export const btnModal = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${variables.gutters.gut15} ${variables.gutters.gut20};
  ${uClrDark};
  text-align: center;
  font-size: ${fonts.size.md};
  font-family: ${fonts.family.medium};
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  border: none;
  ${uBkgLight};
  border-radius: ${variables.radius.base};
`;

export const btnModalActive = css`
  background-color: ${colors.orange};
  ${uClrLight};
`;

export const btnModalBig = css`
  padding: ${variables.gutters.gut20};
  width: ${variables.sizes.modalBtnBigWidth};
`;

export const seeAllLink = css`
  ${uClrLight};
  text-transform: uppercase;
  font-size: ${fonts.size.sm};
  border-bottom: 1px solid currentColor;
  text-decoration: none;
`;

export const btnBaseWithoutPseudo = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${variables.gutters.gut15};
  ${uClrDark};
  text-align: center;
  font-size: ${fonts.size.sm};
  font-family: ${fonts.family.bold};
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  border: none;
  ${uBkgLight};
  border-radius: 3px;
`;
