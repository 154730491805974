import { io } from 'socket.io-client';

export default function makeSocketConnection(macAddress) {
  const socket = io(
    `${process.env.REACT_APP_SOCKETS_BASE_URL}?mac_address=${macAddress}`,
    { path: '/sockets' }
  );

  socket.on('reload', () => {
    // Without true it uses cached version
    window.location.reload(true);
  });
}
