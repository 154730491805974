import ApiService from './ApiService';

const SHOP_CATEGORY_TYPE = 'shops';
const DINE_CATEGORY_TYPE = 'dines';
const ENTERTAINMENT_CATEGORY_TYPE = 'entertainments';
const ENDPOINTS = {
  CATEGORIES_DINE: `/categories/resident/?type=${DINE_CATEGORY_TYPE}`,
  CATEGORIES_SHOP: `/categories/resident/?type=${SHOP_CATEGORY_TYPE}`,
  CATEGORIES_ENTERTAINMENTS: `/categories/resident/?type=${ENTERTAINMENT_CATEGORY_TYPE}`
};

class CategoryService extends ApiService {
  getDinesCategories = () => this.apiClient.get(ENDPOINTS.CATEGORIES_DINE);

  getShopsCategories = () => this.apiClient.get(ENDPOINTS.CATEGORIES_SHOP);

  getEntertainmentCategories = () =>
    this.apiClient.get(ENDPOINTS.CATEGORIES_ENTERTAINMENTS);
}

const categoryService = new CategoryService();
export default categoryService;
