import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { floorsSelector } from '../../../redux/selectors/floorSelectors';
import { btnBase, btnLight, btnOrange, btnSm } from '../../../styles/buttons';
import { mapFloors } from '../../../styles/components/MapScreen';
import colors from '../../../styles/constants/colors';
import { uClrLight, uGapBottom15 } from '../../../styles/helpers';

const filterBtn = css`
  ${btnBase}
  ${btnSm}
  ${uGapBottom15}
  ${uClrLight}
  &:active,
  &:focus {
    ${uClrLight};
    background-color: ${colors.orange};
  }
`;

const Floor = ({ floor, isSelected, selectFloor }) => (
  <button
    onClick={() => selectFloor(floor)}
    css={[filterBtn, isSelected ? btnOrange : btnLight]}
    type="button"
  >
    {floor.name}
  </button>
);

Floor.propTypes = {
  floor: PropTypes.object,
  isSelected: PropTypes.bool,
  selectFloor: PropTypes.func
};

const MapFloors = ({ onSelect, activeFloor }) => {
  const floors = useSelector(floorsSelector);

  return (
    <div css={mapFloors}>
      {floors.map(floor => (
        <Floor
          key={floor.id}
          floor={floor}
          isSelected={floor.id === activeFloor}
          selectFloor={onSelect}
        />
      ))}
    </div>
  );
};

MapFloors.propTypes = {
  onSelect: PropTypes.func,
  activeFloor: PropTypes.number
};

export default MapFloors;
