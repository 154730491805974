import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { modalEventContentSet } from '../../../redux/actions/modalActions';
import { trendingEventsSelector } from '../../../redux/selectors/eventsSelector';
import HomeList from './HomeList';

const TrendingEventsList = () => {
  const { t } = useTranslation();
  const events = useSelector(trendingEventsSelector).slice(0, 6);

  const dispatch = useDispatch();

  const handleClick = slug => {
    const selectedEvent = events.find(event => event.slug === slug);
    dispatch(modalEventContentSet(selectedEvent));
  };

  const formList = () =>
    events.map(event => ({
      logo: event.image,
      ...event,
      type: t('common.event')
    }));

  return (
    <HomeList
      list={formList()}
      onClick={handleClick}
      typeKey="common.events"
      title="events.trendingEvents"
    />
  );
};

export default TrendingEventsList;
