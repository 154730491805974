/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { RESIDENT_TYPES } from '../../constants';
import { setFocusedResidentItem } from '../../redux/actions/mapActions';
import { btnBase } from '../../styles/buttons';
import {
  ModalBody,
  modalBtn,
  ModalBtnWrapper,
  modalImgStyle,
  ModalTextWithLineBreak,
  ModalTitle,
  ModalTitleWrapper
} from '../../styles/components/ModalStyle';
import colors from '../../styles/constants/colors';
import { uGapTop30, uTextUppercase } from '../../styles/helpers';
import { useCloseModal } from '../../utils/hooks';
import Image from '../common/Image/Image';
import ModalSlider from '../common/ModalSlider/ModalSlider';
import ResidentContactDetails from './ResidentContactDetails';
import ResidentLocationDetail from './ResidentLocationDetail';
import ResidentOpeningHours from './ResidentOpeningHours';

export const SingleResidentModalHeader = ({ gallery, cover, title, type }) => {
  const images = [{ file: { original: cover } }, ...gallery];

  const getTitleColor = () => {
    switch (type) {
    case RESIDENT_TYPES.SHOP:
      return colors.brandPurple;
    case RESIDENT_TYPES.ENTERTAINMENT:
      return colors.brandYellow;
    case RESIDENT_TYPES.DINE:
      return colors.brandGreen;
    default:
      return colors.redViolet;
    }
  };

  return (
    <>
      <ModalSlider variableWidth={false}>
        {images.map(img => (
          <div key={img.file.original}>
            <Image css={modalImgStyle} src={img.file.original} />
          </div>
        ))}
      </ModalSlider>

      <ModalTitleWrapper color={getTitleColor()}>
        <h4 css={uTextUppercase}>{title}</h4>
      </ModalTitleWrapper>
    </>
  );
};

SingleResidentModalHeader.propTypes = {
  cover: PropTypes.string,
  title: PropTypes.string,
  gallery: PropTypes.array,
  type: PropTypes.string
};

export const SingleResidentModalBody = ({
  description,
  contact_details,
  properties,
  where_to_find_us,
  opening_hours
}) => {
  const { t } = useTranslation();

  return (
    <ModalBody>
      {!!description && (
        <ModalTextWithLineBreak>{description}</ModalTextWithLineBreak>
      )}
      <ModalTitle css={uGapTop30}>{t('resident.openingHours')}</ModalTitle>
      <ResidentOpeningHours openingHours={opening_hours} />

      <ResidentLocationDetail
        properties={properties}
        where_to_find_us={where_to_find_us}
        t={t}
      />
      <ResidentContactDetails contact_details={contact_details} t={t} />
    </ModalBody>
  );
};

SingleResidentModalBody.propTypes = {
  description: PropTypes.string,
  contact_details: PropTypes.object,
  properties: PropTypes.object,
  where_to_find_us: PropTypes.array,
  opening_hours: PropTypes.array
};

export const SingleResidentModalFooter = ({ item }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const closeModal = useCloseModal();

  const onFocus = useCallback(
    event => {
      event.stopPropagation();
      dispatch(setFocusedResidentItem(item));

      history.push(`/map?id=${item.id}`);
      closeModal();
    },
    [closeModal, dispatch, history, item]
  );

  const { t } = useTranslation();

  return (
    <ModalBtnWrapper>
      <button css={[btnBase, modalBtn]} type="button" onClick={e => onFocus(e)}>
        {t('resident.showOnMap')}
      </button>
    </ModalBtnWrapper>
  );
};

SingleResidentModalFooter.propTypes = {
  item: PropTypes.object
};
