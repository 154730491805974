import PropTypes from 'prop-types';
import React from 'react';

import { btnModal, btnModalActive } from '../../../styles/buttons';
import {
  ModalFilterContainer,
  ModalFilterTitle
} from '../../../styles/components/ModalStyle';
import {
  uGapBottom20,
  uGapBottom30,
  uGapRight20
} from '../../../styles/helpers';

const FilterChecker = ({ filter, handleCheck, selected }) => (
  <button
    css={[
      uGapRight20,
      uGapBottom20,
      btnModal,
      selected ? btnModalActive : null
    ]}
    type="button"
    onClick={() => handleCheck(filter)}
  >
    <p>{filter.name}</p>
  </button>
);

FilterChecker.propTypes = {
  filter: PropTypes.object,
  handleCheck: PropTypes.func,
  selected: PropTypes.bool
};

const ModalFilterSection = ({ filters, isSelected, title, handleToggle }) => (
  <div>
    <ModalFilterTitle css={uGapBottom30}>{title}</ModalFilterTitle>
    <ModalFilterContainer css={uGapBottom30}>
      {filters &&
        filters.map((filter, index) => (
          <FilterChecker
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            filter={filter}
            handleCheck={handleToggle}
            selected={isSelected(filter)}
          />
        ))}
    </ModalFilterContainer>
  </div>
);

ModalFilterSection.propTypes = {
  filters: PropTypes.array,
  isSelected: PropTypes.func,
  title: PropTypes.string,
  handleToggle: PropTypes.func
};

export default ModalFilterSection;
