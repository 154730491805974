export function initAllFoors() {
  const params = new URLSearchParams(window.location.search);
  const allFloors = params.get('all_floors');

  localStorage.setItem('all_floors', allFloors);
}

export function getAllFloors() {
  return localStorage.getItem('all_floors');
}
