import styled from '@emotion/styled';

import colors from '../constants/colors';
import fonts from '../constants/fonts';
import variables from '../constants/variables';
import { uClrDark, uClrLight } from '../helpers';

export const TabFilterWrap = styled.div`
  grid-column: span 4;
  display: flex;
  align-items: center;
  overflow: auto;
  scrollbar-width: none;
  margin-top: ${variables.gutters.gut20};
  margin-bottom: ${variables.gutters.gut30};

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
`;

export const FilterButton = styled.button`
  margin-right: ${variables.gutters.gut15};
  padding: ${variables.gutters.gut10} ${variables.gutters.gut15};
  font-size: ${fonts.size.sm};
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  white-space: nowrap;
  background-color: ${props =>
    props.isSelected ? colors.lightPeriwinkle : 'transparent'};
  ${props => (props.isSelected ? uClrDark : uClrLight)};
  border-radius: 3px;

  &:focus {
    outline: none;
  }
`;
