import {
  ENTERTAINMENT_CATEGORIES_SET,
  ENTERTAINMENT_INFO_SET,
  ENTERTAINMENT_SELECT_CATEGORY_SET,
  ENTERTAINMENT_SELECT_FLOOR_SET,
  ENTERTAINMENT_TOP_SET,
  ENTERTAINMENTS_APPEND,
  ENTERTAINMENTS_RESET,
  ENTERTAINMENTS_SET
} from '../actions/entertainmentActions';
import createReducer from '../util';

const initialState = {
  entertainments: [],
  info: {},
  selectedFloors: [],
  selectedCategories: [],
  categories: [],
  topEntertainments: []
};

export default createReducer(
  {
    [ENTERTAINMENTS_SET]: setEntertainments,
    [ENTERTAINMENT_INFO_SET]: setEntertainmentsInfo,
    [ENTERTAINMENT_SELECT_CATEGORY_SET]: setSelectedCategories,
    [ENTERTAINMENT_SELECT_FLOOR_SET]: setSelectedFloors,
    [ENTERTAINMENT_CATEGORIES_SET]: setEntertainmentCategories,
    [ENTERTAINMENT_TOP_SET]: setTopEntertainments,
    [ENTERTAINMENTS_RESET]: resetEntertainments,
    [ENTERTAINMENTS_APPEND]: appendEntertainments
  },
  initialState
);

function setEntertainments(state, action) {
  return {
    ...state,
    entertainments: action.payload.results,
    next: action.payload.next,
    count: action.payload.count
  };
}

function setTopEntertainments(state, action) {
  return {
    ...state,
    topEntertainments: action.payload.filter(
      entertainment => entertainment.top_entertainment
    )
  };
}

function setEntertainmentsInfo(state, action) {
  const { count, characters } = action.payload;
  const info = { total: count, letters: characters };

  return {
    ...state,
    info
  };
}

function setSelectedFloors(state, action) {
  return {
    ...state,
    selectedFloors: action.payload
  };
}

function setSelectedCategories(state, action) {
  return {
    ...state,
    selectedCategories: action.payload
  };
}

function setEntertainmentCategories(state, action) {
  return {
    ...state,
    categories: action.payload
  };
}

function resetEntertainments() {
  return { ...initialState };
}

function appendEntertainments(state, action) {
  return {
    ...state,
    entertainments: [...state.entertainments, ...action.payload.results],
    next: action.payload.next,
    count: action.payload.count
  };
}
