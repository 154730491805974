import PropTypes from 'prop-types';
import React from 'react';

const ModalHeader = ({ children }) => {
  return <div>{children}</div>;
};

ModalHeader.propTypes = {
  children: PropTypes.node
};

export default ModalHeader;
