import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

const Container = styled.div`
  display: ${props => (props.isActive ? 'block' : 'none')};
`;

const HiddenRoute = ({ location, to, component: Component }) => {
  const isActive = location.pathname.includes(to);

  return (
    <Container isActive={isActive}>
      <Component isFocused={isActive} />
    </Container>
  );
};

HiddenRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
  to: PropTypes.string
};

export default withRouter(HiddenRoute);
