import {
  DINE_CATEGORIES_SET,
  DINE_SELECT_CATEGORY_SET,
  DINE_SELECT_FLOOR_SET,
  DINES_APPEND,
  DINES_HAVE_YOU_TRIED_SET,
  DINES_INFO_SET,
  DINES_RESET,
  DINES_SET
} from '../actions/dineActions';
import createReducer from '../util';

const initialState = {
  dines: [],
  haveYouTried: [],
  searchInfo: {},
  selectedFloors: [],
  selectedCategories: [],
  dineCategories: [],
  count: 0,
  next: null
};

export default createReducer(
  {
    [DINES_SET]: setDines,
    [DINES_HAVE_YOU_TRIED_SET]: setHaveYouTried,
    [DINES_INFO_SET]: setDinesInfo,
    [DINE_SELECT_CATEGORY_SET]: setSelectedCategories,
    [DINE_SELECT_FLOOR_SET]: setSelectedFloors,
    [DINE_CATEGORIES_SET]: setDineCategories,
    [DINES_APPEND]: appendDines,
    [DINES_RESET]: resetDines
  },
  initialState
);

function setDines(state, action) {
  return {
    ...state,
    dines: action.payload.results,
    next: action.payload.next,
    count: action.payload.count
  };
}

function setHaveYouTried(state, action) {
  return {
    ...state,
    haveYouTried: action.payload
  };
}

function setDinesInfo(state, action) {
  const { count, characters } = action.payload;
  const searchInfo = { total: count, letters: characters };

  return {
    ...state,
    searchInfo
  };
}

function setSelectedFloors(state, action) {
  return {
    ...state,
    selectedFloors: action.payload
  };
}

function setSelectedCategories(state, action) {
  return {
    ...state,
    selectedCategories: action.payload
  };
}

function setDineCategories(state, action) {
  return {
    ...state,
    dineCategories: action.payload
  };
}

function appendDines(state, action) {
  return {
    ...state,
    dines: [...state.dines, ...action.payload.results],
    next: action.payload.next,
    count: action.payload.count
  };
}

function resetDines() {
  return { ...initialState };
}
