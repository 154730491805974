import { createSelector } from 'reselect';

const commonSelector = state => state.common;

export const alertNotificationsSelector = createSelector(
  commonSelector,
  state => state.alertNotifications
);

export const alertNotifications = createSelector(
  alertNotificationsSelector,
  state => state.alertNotification
);

export const alertNotificationError = createSelector(
  alertNotificationsSelector,
  state => state.alertNotificationError
);
