import 'react-simple-keyboard/build/css/index.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Keyboard from 'react-simple-keyboard';

import FilterIcon from '../../assets/icons/filter.svg';
import { RESIDENT_TYPES } from '../../constants';
import {
  entertainmentCategoriesGet,
  entertainmentSelectedCategorySet,
  entertainmentSelectedFloorSet,
  entertainmentsInfoGet,
  filterEntertainments,
  getEntertainment,
  getEntertainments,
  getTopEntertainments,
  resetEntertainments
} from '../../redux/actions/entertainmentActions';
import { modalFilterContentSet } from '../../redux/actions/modalActions';
import {
  allEntertainmentsSelector,
  entertainmentCategoriesSelector,
  entertainmentInfoSelector,
  entertainmentSelectedCategoriesSelector,
  entertainmentSelectedFloorsSelector,
  entertainmentsTopSelector,
  paginationDataSelector
} from '../../redux/selectors/entertainmentsSelectors';
import { floorsSelector } from '../../redux/selectors/floorSelectors';
import { isOnlineSelector } from '../../redux/selectors/networkSelector';
import colors from '../../styles/constants/colors';
import { Header, PageContainer } from '../../styles/container';
import { FilterStyled, FilterWrapper } from '../../styles/filter';
import {
  uBkgBrandYellow,
  uGapRight15,
  uHeight100,
  uTextUppercase
} from '../../styles/helpers';
import ResidentListContainer from '../common/resident/ResidentListContainer';
import ResidentTopList from '../resident/ResidentTopList';

const Entertainments = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedLetter, setSelectedLetter] = useState('');
  const floors = useSelector(floorsSelector);
  const categories = useSelector(entertainmentCategoriesSelector);
  const topEntertainments = useSelector(entertainmentsTopSelector);
  const online = useSelector(isOnlineSelector);
  const selectedFloors = useSelector(entertainmentSelectedFloorsSelector);
  const selectedCategories = useSelector(
    entertainmentSelectedCategoriesSelector
  );
  const info = useSelector(entertainmentInfoSelector);
  const entertainments = useSelector(allEntertainmentsSelector);

  const { next, count } = useSelector(paginationDataSelector);

  const handleLoadMore = () => {
    dispatch(
      getEntertainments({
        letter: selectedLetter,
        categories: selectedCategories.map(cat => cat.id),
        floors: selectedFloors.map(floor => floor.id),
        search: '**'
      })
    );
  };

  const [keyboardVisibility, setKeyboardVisibility] = useState(false);
  const [input, setInput] = useState('');
  const [layout, setLayout] = useState('default');
  const keyboard = React.useRef();

  useEffect(() => {
    function clickHanlder(e) {
      if (
        !(e.target.nodeName === 'INPUT') &&
        !e.target.classList.contains('hg-button')
      ) {
        setKeyboardVisibility(false);
      }
    }

    window.addEventListener('click', clickHanlder);
    return window.removeEventListener('click', clickHanlder, true);
  }, []);

  // eslint-disable-next-line no-shadow
  const onChange = input => {
    setInput(input);
  };

  const handleShift = () => {
    const newLayoutName = layout === 'default' ? 'shift' : 'default';
    setLayout(newLayoutName);
  };

  const onKeyPress = button => {
    if (button === '{bksp}') {
      setInput('');
    }
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === '{shift}' || button === '{lock}') handleShift();
  };

  const onChangeInput = event => {
    // eslint-disable-next-line no-shadow
    const input = input || event.target.value;
    setInput(input);
    keyboard.current.setInput(input);
  };

  useEffect(
    () => {
      dispatch(getTopEntertainments());
      dispatch(entertainmentCategoriesGet());
      dispatch(entertainmentsInfoGet());

      const resetOnUnmount = () => {
        dispatch(resetEntertainments());
        setSelectedLetter('');
        setSelectedCategory([]);
        setSelectedFloors([]);
      };

      return () => resetOnUnmount();
    },
    [dispatch, online]
  );

  useEffect(
    () => {
      dispatch(
        filterEntertainments({
          letter: selectedLetter,
          categories: selectedCategories.map(cat => cat.id),
          floors: selectedFloors.map(floor => floor.id),
          search: '**'
        })
      );
    },
    [selectedLetter, dispatch, online]
  );

  useEffect(
    () => {
      dispatch(
        filterEntertainments({
          letter: input,
          categories: selectedCategories.map(cat => cat.id),
          floors: selectedFloors.map(floor => floor.id),
          search: '**'
        })
      );
    },
    [input, online]
  );

  const setSelectedCategory = useCallback(
    data => dispatch(entertainmentSelectedCategorySet(data)),
    [dispatch]
  );
  const setSelectedFloors = useCallback(
    data => dispatch(entertainmentSelectedFloorSet(data)),
    [dispatch]
  );

  const handleFilterClick = () =>
    dispatch(
      modalFilterContentSet({
        floors,
        categories,
        selectedFloors,
        selectedCategories,
        selectedLetter,
        setSelectedFloors,
        setSelectedCategory,
        filterAction: filterEntertainments
      })
    );

  const handleEntertainmentClick = slug => {
    dispatch(getEntertainment(slug));
  };
  const isFloorOrCategoryToggled = () =>
    selectedCategories.length || selectedFloors.length;

  return (
    <div css={uHeight100}>
      <Header css={uBkgBrandYellow}>
        <h1 css={uTextUppercase}>{t('common.entertainment')}</h1>
      </Header>
      <PageContainer>
        <ResidentTopList
          title={t('entertainments.topEntertainments')}
          list={topEntertainments}
          onClick={handleEntertainmentClick}
        />
        {online && (
          <div>
            <div style={{ flex: 1, width: '90%', marginBottom: 20 }}>
              <input
                style={{
                  width: '100%',
                  height: 60,
                  fontSize: 22,
                  paddingLeft: 15
                }}
                className="input_search"
                onFocus={() => {
                  setKeyboardVisibility(true);
                }}
                value={input}
                placeholder={t('search.search_totem')}
                onChange={onChangeInput}
              />
              {keyboardVisibility && (
                <Keyboard
                  style={{ width: '100%' }}
                  // eslint-disable-next-line no-return-assign
                  keyboardRef={r => (keyboard.current = r)}
                  layoutName={layout}
                  onChange={onChange}
                  onKeyPress={onKeyPress}
                />
              )}
            </div>
            <FilterWrapper>
              <FilterStyled
                css={uGapRight15}
                onClick={handleFilterClick}
                type="button"
                isSelected={isFloorOrCategoryToggled()}
              >
                <FilterIcon
                  fill={
                    isFloorOrCategoryToggled() ? colors.white : colors.black
                  }
                />
              </FilterStyled>
            </FilterWrapper>
          </div>
        )}
        <ResidentListContainer
          list={entertainments}
          info={info}
          count={count}
          handleItemClick={handleEntertainmentClick}
          type={RESIDENT_TYPES.ENTERTAINMENT}
          selectedLetter={selectedLetter}
          setSelectedLetter={setSelectedLetter}
          handleLoadMore={handleLoadMore}
          hasMore={!!next}
        />
      </PageContainer>
    </div>
  );
};

export default Entertainments;
