import { DEFAULT_RESIDENT_LIMIT, RESIDENT_TYPES } from '../constants';
import ApiService from './ApiService';

const ENDPOINTS = {
  DINES_SEARCH: '/dine_search/',
  DINE: '/dine/',
  INFO: '/residents_first_letters/'
};

class DineService extends ApiService {
  getDines = (params, next) =>
    this.apiClient.get(next || ENDPOINTS.DINES_SEARCH, {
      params: { ...params, limit: DEFAULT_RESIDENT_LIMIT }
    });

  getDine = slug => this.apiClient.get(`${ENDPOINTS.DINE}${slug}/`);

  getInfo = () =>
    this.apiClient.get(ENDPOINTS.INFO, {
      params: { type: RESIDENT_TYPES.DINE }
    });
}

export default new DineService();
