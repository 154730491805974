export const INFO_SECTION_SET = 'INFO_SECTION_SET';
export const SPLASH_SCREEN_GET = 'SPLASH_SCREEN_GET';
export const SPLASH_SCREEN_SET = 'SPLASH_SCREEN_SET';
export const BLOBS_SET = 'BLOBS_SET';

export function setInfoSection(payload) {
  return {
    type: INFO_SECTION_SET,
    payload
  };
}

export function splashScreenGet() {
  return {
    type: SPLASH_SCREEN_GET
  };
}

export function splashScreenSet(payload) {
  return {
    type: SPLASH_SCREEN_SET,
    payload
  };
}

export function splashScreenClear() {
  return {
    type: SPLASH_SCREEN_SET,
    payload: null
  };
}

export function blobsSet(payload) {
  return {
    type: BLOBS_SET,
    payload
  };
}
