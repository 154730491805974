import styled from '@emotion/styled';

import colors from '../constants/colors';
import variables from '../constants/variables';
import { uClrDark, uClrLight } from '../helpers';

export const FilterButton = styled.div`
  border-radius: 50%;
  width: ${variables.sizes.letterRound};
  height: ${variables.sizes.letterRound};
  background-color: ${props =>
    props.isSelected ? colors.orange : colors.lightPeriwinkle};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: ${variables.gutters.gut10};
  font-family: 20;
  ${props => (props.isSelected ? uClrLight : uClrDark)};
  cursor: pointer;
`;

export const FiltersWrapper = styled.div`
  overflow: auto;
  display: flex;
  white-space: nowrap;
  align-items: center;
  margin-left: -${variables.gutters.gut30};
  margin-bottom: ${variables.gutters.gut30};
  margin-right: -${variables.gutters.gut30};

  > div {
    padding-left: ${variables.gutters.gut30};
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
