import { format as formatDate } from 'date-fns';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './resources/en.json';
import rsTranslations from './resources/sr.json';

i18n.use(initReactI18next).init({
  lng: localStorage.getItem('lang') || 'sr',
  fallbackLng: 'en',
  debug: true,
  supportedLngs: ['en', 'sr'],
  resources: {
    en: {
      translation: enTranslations
    },
    sr: {
      translation: rsTranslations
    }
  },
  interpolation: {
    format: (value, format) => {
      if (value instanceof Date) {
        return formatDate(value, format);
      }

      return value;
    }
  }
});

export default i18n;
