import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeEventsFilter } from '../../constants/events';
import { PageRightPaddingSm } from '../../styles/container';
import { uTextUppercase } from '../../styles/helpers';
import { eventsTimelineParser, filterListByKey } from '../../utils/events';
import EventsFilter from './EventsFilter';
import Timeline from './Timeline';

const EventTimeline = ({ events }) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState(null);

  const timeLineData = useMemo(
    () => (events.length ? eventsTimelineParser([...events]) : []),
    [events]
  );

  const filterTimeline = () => {
    const timeLineEvents = timeLineData.filter(
      timeLine => !!timeLine.events.length
    );

    if (filter) {
      return filterListByKey(timeLineEvents, filter.keysToFilter);
    }

    return timeLineEvents;
  };

  return (
    <div css={PageRightPaddingSm}>
      <h4 css={uTextUppercase}>{t('events.events')}</h4>
      <EventsFilter
        filters={makeEventsFilter()}
        onClick={setFilter}
        data={timeLineData}
        activeFilter={filter}
        t={t}
      />
      <Timeline timeLine={filterTimeline()} />
    </div>
  );
};

EventTimeline.propTypes = {
  events: PropTypes.array
};

export default EventTimeline;
