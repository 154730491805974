export const baseFontSize = '16px';
export const baseFontSizeSm = '12px';
export const baseFontSizeMd = '14px';
export const baseFontSizeLg = '18px';
export const baseSize = '1rem';

const fonts = {
  family: {
    bold: 'FavoritPro-Bold',
    boldItalic: 'FavoritPro-BoldItalic',
    book: 'FavoritPro-Book',
    bookItalic: 'FavoritPro-BookItalic',
    italic: 'FavoritPro-Italic',
    light: 'FavoritPro-Light',
    lightItalic: 'FavoritPro-LightItalic',
    medium: 'FavoritPro-Medium',
    mediumItalic: 'FavoritPro-MediumItalic',
    regular: 'FavoritPro-Regular'
  },

  weight: {
    light: 200,
    regular: 400,
    medium: 500,
    bold: 700
  },

  size: {
    base: baseSize,
    xs: '0.879rem',
    sm: '1.055rem',
    md: '1.582rem',
    h1: '3.955rem',
    h2: '3.515rem',
    h3: '2.461rem',
    h4: '1.758rem',
    h5: baseSize,
    h6: baseSize,
    h1m: '2.812rem',
    h2m: '2.578rem',
    h3m: '2.109rem'
  },

  lineHeight: {
    base: 1.3,
    heading: 1
  }
};

export default fonts;
