import { SET_FOCUSED_RESIDENT_ITEM } from '../actions/mapActions';
import createReducer from '../util';

const initialState = {
  selectedResident: null
};

const setFocusedResidentItem = (state, action) => ({
  ...state,
  selectedResident: action.payload
});
export default createReducer(
  {
    [SET_FOCUSED_RESIDENT_ITEM]: setFocusedResidentItem
  },
  initialState
);
