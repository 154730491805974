import React from 'react';

import Icons from './assets/icons/arrow-right.svg';
import FoodAndDrinksIcon from './assets/icons/dine.svg';
import EntertainmentIcon from './assets/icons/entertainment.svg';
import EventsIcon from './assets/icons/events.svg';
import HomeIcon from './assets/icons/home.svg';
import InfoIcon from './assets/icons/info.svg';
import MapIcon from './assets/icons/map.svg';
import ShopsIcon from './assets/icons/shops.svg';
import Dines from './components/pages/Dines';
import Entertainments from './components/pages/Entertainments';
import Events from './components/pages/Events';
import Home from './components/pages/Home';
import InfoSection from './components/pages/InfoSection';
import Maps from './components/pages/Maps';
import Offers from './components/pages/Offers';
import Reload from './components/pages/Reload';
import Shops from './components/pages/Shops';
import Splash from './components/pages/Splash';
import i18n from './i18n';

export const navigationMap = () => [
  {
    name: i18n.t('common.home'),
    to: 'home',
    component: Home,
    Icon: HomeIcon
  },
  {
    name: i18n.t('common.map'),
    to: 'map',
    component: Maps,
    Icon: MapIcon,
    hidden: true
  },
  {
    name: i18n.t('common.shops'),
    to: 'shops',
    component: Shops,
    Icon: ShopsIcon
  },
  {
    name: i18n.t('common.dine'),
    to: 'dine',
    component: Dines,
    Icon: FoodAndDrinksIcon
  },
  {
    name: i18n.t('common.events'),
    to: 'events',
    component: Events,
    Icon: EventsIcon
  },
  {
    name: i18n.t('common.entertainment'),
    to: 'entertainments',
    component: Entertainments,
    Icon: EntertainmentIcon
  },
  {
    name: i18n.t('common.info'),
    to: 'info',
    component: InfoSection,
    Icon: InfoIcon
  },
  {
    name: i18n.t('common.splash'),
    to: 'splash',
    component: Splash,
    Icon: Icons,
    excludeFromSidebar: true,
    hideSideBar: true
  },
  {
    name: i18n.t('common.splash'),
    to: 'reload',
    component: Reload,
    Icon: Icons,
    excludeFromSidebar: true,
    hideSideBar: true
  },
  {
    to: 'offers',
    component: Offers,
    excludeFromSidebar: true,
    hideSideBar: false
  }
];

const routesWithWIP = () => {
  return navigationMap()
    .map(link => {
      if (link.action || !link.to) {
        // Skip custom actions, they don't need routes.
        return null;
      }

      if (link.component) {
        return link;
      }

      return {
        ...link,
        component: () => <h1>{link.name} WIP page</h1>
      };
    })
    .filter(Boolean);
};

export const navigation = navigationMap();
export const routes = routesWithWIP();
