import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { modalInfoContentSet } from '../../redux/actions/modalActions';
import { StyledInfoServices } from '../../styles/components/InfoStyle';
import InfoServiceItem from './InfoServiceItem';

const InfoServices = ({ infoServices }) => {
  const dispatch = useDispatch();

  const handleInfoServiceClick = service => {
    dispatch(modalInfoContentSet(service));
  };

  return (
    <StyledInfoServices>
      {infoServices.map(infoService => (
        <InfoServiceItem
          key={infoService.id}
          handleClick={handleInfoServiceClick}
          service={infoService}
        />
      ))}
    </StyledInfoServices>
  );
};

InfoServices.propTypes = {
  infoServices: PropTypes.array
};

export default InfoServices;
