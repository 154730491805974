/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';

import {
  ModalBody,
  ModalTextWithLineBreak,
  ModalTitle,
  ModalTitleWrapper,
  sliderImg
} from '../../styles/components/ModalStyle';
import colors from '../../styles/constants/colors';
import { uHeightFull, uTextUppercase, uWidthFull } from '../../styles/helpers';
import Image from '../common/Image/Image';
import ModalSlider from '../common/ModalSlider/ModalSlider';

export const SingleOfferModalHeader = ({
  offer_title,
  website_image,
  mobile_image
}) => {
  return (
    <>
      <ModalSlider>
        <Image
          css={[sliderImg, uWidthFull, uHeightFull]}
          src={website_image || mobile_image}
        />
      </ModalSlider>

      <ModalTitleWrapper color={colors.redViolet}>
        <h4 css={uTextUppercase}>{offer_title}</h4>
      </ModalTitleWrapper>
    </>
  );
};

SingleOfferModalHeader.propTypes = {
  offer_title: PropTypes.string,
  mobile_image: PropTypes.string,
  website_image: PropTypes.string
};

export const SingleOfferModalBody = ({ brand_name, offer_description }) => {
  return (
    <ModalBody>
      <ModalTitle>{brand_name}</ModalTitle>
      {!!offer_description && (
        <ModalTextWithLineBreak>{offer_description}</ModalTextWithLineBreak>
      )}
    </ModalBody>
  );
};

SingleOfferModalBody.propTypes = {
  offer_description: PropTypes.string,
  brand_name: PropTypes.string
};
