import { MODAL_CLOSE, MODAL_CONTENT_SET } from '../actions/modalActions';
import createReducer from '../util';

const initialState = {
  isVisible: false,
  body: {},
  header: {},
  footer: {},
  style: {},
  hideX: false
};

const modalContentSet = (state, action) => ({
  ...state,
  isVisible: action.isVisible,
  header: action.header,
  footer: action.footer,
  body: action.body,
  style: action.style,
  hideX: action.hideX
});

const modalClose = () => initialState;

export default createReducer(
  {
    [MODAL_CONTENT_SET]: modalContentSet,
    [MODAL_CLOSE]: modalClose
  },
  initialState
);
