import { Global } from '@emotion/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HashRouter, Redirect, Route } from 'react-router-dom';

import RouteIntercept from './components/RouteIntercept';
import CommonModal from './components/shared/modal/CommonModal';
import useSetLanguage from './hooks/useSetLanguage';
import { setIsOnline } from './redux/actions/networkActions';
import HiddenRoute from './router/HiddenRoute';
import { routes } from './routes';
import authKioskService from './services/AuthKioskService';
import baseStyles from './styles/baseStyles';
import { getMacAddress } from './utils/get-mac';
import makeSocketConnection from './utils/socket';
import Worker from './workers/internet.worker';

export default function App() {
  const dispatch = useDispatch();
  useSetLanguage();

  const setOnline = () => {
    dispatch(setIsOnline(true));
  };
  const setOffline = () => {
    dispatch(setIsOnline(false));
  };
  const onLoad = () => {
    const macAddress = getMacAddress();
    if (macAddress) {
      const internetSpeedWorker = new Worker();
      internetSpeedWorker.postMessage([macAddress]);
    }
  };

  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);
    window.addEventListener('load', onLoad);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
      window.removeEventListener('load', onLoad);
    };
  }, []);

  useEffect(() => {
    const macAddress = getMacAddress();
    try {
      if (macAddress) {
        authKioskService.register(macAddress);
        makeSocketConnection(macAddress);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <HashRouter>
      <Global styles={baseStyles} />
      <RouteIntercept>
        {routes.map(({ to, component, hidden }) => {
          if (hidden) {
            return <HiddenRoute key={to} to={to} component={component} />;
          }

          return <Route key={to} path={`/${to}`} component={component} />;
        })}
        <Redirect to="/splash" />
        <CommonModal />
      </RouteIntercept>
    </HashRouter>
  );
}
