import { MAC_ADDRESS_KEY } from './AuthKioskService';

// Actions
const GO_TO_FLOOR = 'goToFloor';
const RESET_MAP = 'resetMapFocus';
const GO_TO_STORE = 'goToStore';
const RELOAD_MAP = 'reloadMap';

// Params
const ZOOM_PARAM = 'zoom';
const MAX_ZOOM_PARAM = 'max_zoom';
const KIOSK_MAC_ADDRESS = 'kiosk_mac_address';

const MAX_ZOOM = 15.0;
const MIN_ZOOM = 13.0;

const SIX_HOURS = 6 * 60;

class MapService {
  constructor() {
    this.token = null;
    this.iframe = null;
    this.lastLoad = Date.now();
  }

  storeRef(iframe) {
    this.iframe = iframe;
  }

  getUrl() {
    const params = new URLSearchParams(); // eslint-disable-line

    params.append(ZOOM_PARAM, MIN_ZOOM);
    params.append(MAX_ZOOM_PARAM, MAX_ZOOM);
    params.append(KIOSK_MAC_ADDRESS, localStorage.getItem(MAC_ADDRESS_KEY));

    return `${process.env.REACT_APP_MAPS_BASE_URL}?${params.toString()}`;
  }

  setToken(token) {
    this.token = token;
  }

  reloadIframe() {
    this.iframe.src = this.getUrl();
  }

  changeFloor(floor) {
    this.sendMessage(GO_TO_FLOOR, floor);
  }

  resetMap() {
    this.sendMessage(RESET_MAP, {
      maxZoom: MAX_ZOOM,
      minZoom: MIN_ZOOM,
      zoom: MIN_ZOOM
    });
  }

  reload() {
    if (!this.iframe) {
      return;
    }

    const now = Date.now();
    const passedTime = Math.abs(now - this.lastLoad);
    const minutes = passedTime / 1000 / 60;

    if (minutes > SIX_HOURS) {
      this.sendMessage(RELOAD_MAP, {});
      this.lastLoad = now;
    }
  }

  navigateToResident(residentId, type) {
    this.sendMessage(GO_TO_STORE, {
      resident: residentId,
      type
    });
  }

  sendMessage(command, data) {
    const sendData = JSON.stringify({
      command,
      data
    });

    this.iframe.contentWindow.postMessage(sendData, '*');
  }
}

export default new MapService();
