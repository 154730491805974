import { all } from 'redux-saga/effects';

import commonSaga from './commonSaga';
import dineSaga from './dineSaga';
import entertainmentSaga from './EntertainmentsSaga';
import eventSaga from './eventSaga';
import floorSaga from './floorSaga';
import shopSaga from './shopSaga';

export default function* rootSaga() {
  yield all([
    commonSaga(),
    eventSaga(),
    shopSaga(),
    entertainmentSaga(),
    floorSaga(),
    dineSaga()
  ]);
}
