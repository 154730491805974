import PropTypes from 'prop-types';
import React from 'react';

import AlertNotification from './AlertNotification';

const AlertNotifications = ({ alertNotification, alertNotificationError }) => {
  if (alertNotificationError) {
    return <div>{alertNotificationError}</div>;
  }

  if (!alertNotification) {
    return null;
  }

  return (
    <AlertNotification
      key={alertNotification.id}
      alertNotification={alertNotification}
    />
  );
};

AlertNotifications.propTypes = {
  alertNotification: PropTypes.object,
  alertNotificationError: PropTypes.string
};

export default AlertNotifications;
