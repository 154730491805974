/* eslint-disable camelcase */
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { btnBase } from '../../styles/buttons';
import {
  ModalBody,
  ModalBodyGap,
  modalBtn,
  ModalBtnWrapper,
  ModalText,
  ModalTopRadius
} from '../../styles/components/ModalStyle';
import variables from '../../styles/constants/variables';
import { uTextUppercase } from '../../styles/helpers';
import { useCloseModal } from '../../utils/hooks';

export const InfoModalHeader = ({ title }) => {
  return (
    <ModalBody css={ModalTopRadius}>
      <h4 css={uTextUppercase}>{title}</h4>
    </ModalBody>
  );
};

InfoModalHeader.propTypes = {
  title: PropTypes.string
};

const ModalQRCodeBody = styled.div`
  margin-top: ${variables.gutters.gut10};
`;

export const InfoModalBody = ({ description, qr_code }) => {
  const { t } = useTranslation();
  const closeModal = useCloseModal();

  return (
    <>
      <ModalBody css={ModalBodyGap}>
        <ModalText>{description}</ModalText>
        {!!qr_code && (
          <ModalQRCodeBody>
            <QRCode bgColor="transparent" size={70} value={qr_code} />
          </ModalQRCodeBody>
        )}
      </ModalBody>
      <ModalBtnWrapper>
        <button css={[btnBase, modalBtn]} type="button" onClick={closeModal}>
          {t('common.okay')}
        </button>
      </ModalBtnWrapper>
    </>
  );
};

InfoModalBody.propTypes = {
  description: PropTypes.string,
  qr_code: PropTypes.string
};

export const InfoModalFooter = () => <div />;
