import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import CloseIcon from '../../../assets/icons/close-rounded.svg';
import {
  CloseIconWrapper,
  ModalContainer,
  ModalWrapper
} from '../../../styles/components/ModalStyle';

const Modal = ({ open, onClose, children, hideX, style = {} }) => {
  const backDrop = useRef();

  const handleCloseModal = event => {
    if (event.target === backDrop.current) {
      onClose();
    }
  };

  useEffect(
    () => {
      document.body.style.overflowY = open ? 'hidden' : 'auto';
    },
    [open]
  );

  return open ? (
    <ModalWrapper
      onMouseDown={handleCloseModal}
      ref={backDrop}
      tabIndex="0"
      role="button"
      isBlured={!!style.isBlured}
    >
      <ModalContainer width={style.width}>{children}</ModalContainer>
      {!hideX && (
        <CloseIconWrapper type="button" onClick={onClose}>
          <CloseIcon />
        </CloseIconWrapper>
      )}
    </ModalWrapper>
  ) : null;
};
Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  hideX: PropTypes.bool
};

export default Modal;
