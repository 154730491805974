import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ModalBody,
  ModalBodyGap,
  modalImgStyle,
  ModalText,
  ModalTextWithLineBreak,
  ModalTitle,
  ModalTitleWrapper
} from '../../styles/components/ModalStyle';
import {
  uBkgPink,
  uFlex,
  uGapRight10,
  uTextUppercase
} from '../../styles/helpers';
import {
  formatEventTime,
  formatSingleDate,
  parseEventDate
} from '../../utils/date';
import { parseDistrictAndFloors } from '../../utils/parseDistrictAndFloors';
import Image from '../common/Image/Image';

const EventDates = ({ eventDates }) => {
  const dateTimes = eventDates.reduce(
    (acc, eventDate) => {
      acc[0].push(<div>{formatSingleDate(eventDate.date)}</div>);
      acc[1].push(
        <div>
          {eventDate.start_time}-{eventDate.end_time}
        </div>
      );
      return acc;
    },
    [[], []]
  );
  return (
    <div css={uFlex}>
      <div css={uGapRight10}>{dateTimes[0]}</div>
      <div>{dateTimes[1]}</div>
    </div>
  );
};

EventDates.propTypes = {
  eventDates: PropTypes.array
};

export const EventModalHeader = ({
  image,
  title,
  start_date,
  end_date,
  event_dates,
  districts,
  floors
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Image src={image} css={modalImgStyle} />
      <ModalTitleWrapper css={uBkgPink}>
        <h4 css={uTextUppercase}>{title}</h4>
      </ModalTitleWrapper>
      <ModalBody>
        <ModalTitle>{t('events.times')} &nbsp;</ModalTitle>
        {event_dates.length ? (
          <EventDates eventDates={event_dates} />
        ) : (
          <>
            <ModalText>{parseEventDate(start_date, end_date)}</ModalText>
            <ModalText>{formatEventTime(start_date, end_date)}</ModalText>
          </>
        )}

        <ModalText>{parseDistrictAndFloors(districts, floors)}</ModalText>
      </ModalBody>
    </>
  );
};

EventModalHeader.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  districts: PropTypes.array,
  floors: PropTypes.array,
  event_dates: PropTypes.array
};

export const EventModalBody = ({ description }) => {
  const { t } = useTranslation();

  return (
    <ModalBody css={ModalBodyGap}>
      <ModalTitle>{t('events.about')}</ModalTitle>
      <ModalTextWithLineBreak>{description}</ModalTextWithLineBreak>
    </ModalBody>
  );
};

EventModalBody.propTypes = { description: PropTypes.string };

export const EventModalFooter = () => <div />;
