export default {
  tabIconDefault: '#ccc',
  tabIconSelected: '#2f95dc',
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: '#2f95dc',
  noticeText: '#fff',

  // LOYALTY TIER COLORS
  platinum: '#1d71b8',
  gold: '#faca03',
  silver: '#959595',
  bronze: '#e56324',

  redViolet: '#9b306f',
  brandGreen: '#6e9e31',
  brandRed: '#e6262b',
  brandPink: '#e78ea9',
  brandOrange: '#f08004',
  brandPurple: '#9f72db',
  orange: '#F9570A',
  brandSalmon: '#ffb19a',
  brandYellow: '#ffae00',
  white: '#ffffff',
  white10: 'rgba(255,255,255,0.1)',
  white50: 'rgba(255,255,255,0.5)',
  white40: 'rgba(255,255,255,0.4)',
  whiteDirty: '#F1F1F1',
  gray: '#cccccc',
  gray1: 'rgba(187,187,187,0.5)',
  gray2: '#7f7f7f',
  grayLight: '#e7e7e7',
  grayLight2: '#eeeeee',
  grayLight3: '#e8e8e8',
  grayDark: '#bbbbbb',
  grayDark2: '#313131',
  inputGray: '#f2f2f2',
  black: '#000000',
  black05: 'rgba(0, 0, 0, 0.05)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black65: 'rgba(0, 0, 0, 0.65)',
  black10: 'rgba(0, 0, 0, 0.1)',
  black80: 'rgba(0, 0, 0, 0.8)',
  error: '#F93F0D',

  yankeesBlue: '#1f233d',
  deepKoamaru: '#2d325c',
  lightPeriwinkle: '#c5c7e0'
};
