import PropTypes from 'prop-types';
import React from 'react';

import { ModalText, ModalTitle } from '../../styles/components/ModalStyle';
import { uGapTop30 } from '../../styles/helpers';

const ResidentContactDetails = ({ contact_details, t }) => {
  return (
    <div css={uGapTop30}>
      {contact_details &&
        (!!contact_details.phone || !!contact_details.email) && (
          <>
            <ModalTitle>{t('resident.contact')}</ModalTitle>
            <ModalText>{contact_details.phone}</ModalText>
            <ModalText>{contact_details.email}</ModalText>
          </>
      )}
    </div>
  );
};

ResidentContactDetails.propTypes = {
  contact_details: PropTypes.object,
  t: PropTypes.func
};

export default ResidentContactDetails;
