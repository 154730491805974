import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';

import { defaultSliderSettings } from '../../../constants/sliderSettings';

const DotSlider = ({
  children,
  autoplay,
  slidesToShow,
  slidesToScroll,
  css,
  items,
  afterChange,
  ...props
}) => {
  let timerId = null;
  const settings = {
    ...defaultSliderSettings,
    dots: true,
    infinite: false,
    slidesToShow,
    slidesToScroll,
    afterChange: index => {
      scheduleIndex(index);
      afterChange(index);
    },
    appendDots: () => <></>
  };
  const durations = items.map(({ duration }) => duration);

  const sliderRef = useRef(null);

  const scheduleIndex = index => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      if (!sliderRef.current) {
        return;
      }

      const isLastSlide = index === items.length - 1;
      if (isLastSlide) {
        sliderRef.current.slickGoTo(0, true);
        return;
      }

      sliderRef.current.slickNext();
    }, durations[index]);
  };

  useEffect(
    () => {
      return () => clearTimeout(timerId);
    },
    [timerId]
  );

  useEffect(
    () => {
      if (durations.length > 1) {
        scheduleIndex(0);
      }
    },
    [durations] // eslint-disable-line
  );

  return (
    <Slider css={css} {...settings} {...props} ref={sliderRef}>
      {children}
    </Slider>
  );
};

DotSlider.propTypes = {
  numberOfElements: PropTypes.number,
  children: PropTypes.node,
  autoplay: PropTypes.bool,
  dotClickDisabled: PropTypes.bool,
  slidesToShow: PropTypes.number,
  items: PropTypes.array,
  afterChange: PropTypes.func,
  slidesToScroll: PropTypes.number,
  css: PropTypes.object
};

DotSlider.defaultProps = {
  slidesToShow: 1,
  slidesToScroll: 1
};

export default DotSlider;
