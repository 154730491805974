import ApiService from './ApiService';

const ENDPOINTS = {
  REGISTER: '/kiosk/'
};

export const MAC_ADDRESS_KEY = 'mac_address';

class AuthKioskService extends ApiService {
  register = address =>
    this.apiClient
      .post(ENDPOINTS.REGISTER, { address }, { headers: this.getAuthHeaders() })
      .catch(() => {})
      .finally(() => localStorage.setItem(MAC_ADDRESS_KEY, address));

  getAuthHeaders = () => ({
    Authorization: `Api-Key ${process.env.REACT_APP_API_KEY}`
  });

  isRegistered = () => !!localStorage.getItem(MAC_ADDRESS_KEY);
}

export default new AuthKioskService();
