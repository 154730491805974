import PropTypes from 'prop-types';
import React from 'react';

import {
  ListCardContainerHalf,
  ListCardWrapperHalf
} from '../../styles/components/ListCardStyle';
import Event from './Event';

const Timeline = ({ timeLine }) => (
  <ListCardWrapperHalf>
    <ListCardContainerHalf>
      {timeLine.map(event => (
        <Event key={event.id} {...event} />
      ))}
    </ListCardContainerHalf>
  </ListCardWrapperHalf>
);

Timeline.propTypes = {
  timeLine: PropTypes.array
};

export default Timeline;
