import { css } from '@emotion/core';

import colors from './constants/colors';
import fonts from './constants/fonts';
import variables from './constants/variables';

export const uHeight100 = css`
  height: 100%;
`;

export const uFlex = css`
  display: flex;
`;

export const uTextUppercase = css`
  text-transform: uppercase;
`;

export const uTextBold = css`
  font-family: ${fonts.family.bold};
`;

export const uTextUnderline = css`
  border-bottom: 2px solid;
`;

export const uClrLight = css`
  color: ${colors.white};
`;

export const uClrDark = css`
  color: ${colors.yankeesBlue};
`;

export const uBkgHeader = css`
  background-color: ${colors.deepKoamaru};
`;

export const uBkgDark = css`
  background-color: ${colors.yankeesBlue};
`;

export const uBkgLight = css`
  background-color: ${colors.lightPeriwinkle};
`;

export const uBkgWhite = css`
  background-color: ${colors.white};
`;

export const uBkgPurple = css`
  background-color: ${colors.brandPurple};
`;

export const uBkgGreen = css`
  background-color: ${colors.brandGreen};
`;

export const uBkgPink = css`
  background-color: ${colors.brandPink};
`;

export const uBkgOrange = css`
  background-color: ${colors.brandOrange};
`;

export const uBkgSalmon = css`
  background-color: ${colors.brandSalmon};
`;

export const uBkgBrandYellow = css`
  background-color: ${colors.brandYellow};
}`;

export const uGapBottom10 = css`
  margin-bottom: ${variables.gutters.gut10};
`;

export const uGapBottom20 = css`
  margin-bottom: ${variables.gutters.gut20};
`;

export const uGapBottom30 = css`
  margin-bottom: ${variables.gutters.gut30};
`;

export const uGapBottom25 = css`
  margin-bottom: ${variables.gutters.gut25};
`;

export const uGapBottom50 = css`
  margin-bottom: ${variables.gutters.gut50};
`;

export const uGapBottom15 = css`
  margin-bottom: ${variables.gutters.gut15};
`;

export const uGapRight10 = css`
  margin-right: ${variables.gutters.gut10};
`;

export const uGapRight15 = css`
  margin-right: ${variables.gutters.gut15};
`;

export const uGapRight20 = css`
  margin-right: ${variables.gutters.gut20};
`;

export const uGapTop30 = css`
  margin-top: ${variables.gutters.gut30};
`;

export const uGapTop50 = css`
  margin-top: ${variables.gutters.gut50};
`;

export const uWidthFull = css`
  width: 100%;
`;

export const uHeightFull = css`
  height: 100%;
`;

export const uCenterBlockElement = css`
  margin: 0 auto;
  display: block;
`;
