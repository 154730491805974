import { createSelector } from 'reselect';

import { parseDateWithFormat } from '../../utils/date';

const offersSelector = state => state.offer;

export const homeOffersSelector = createSelector(offersSelector, state =>
  [...state.offers].sort((first, second) => {
    if (!first.sequence && second.sequence) {
      return 1;
    }
    if (!second.sequence && first.sequence) {
      return -1;
    }
    if (!first.sequence && !second.sequence) {
      return (
        parseDateWithFormat(first.start_date) <
        parseDateWithFormat(second.start_date)
      );
    }
    return first.sequence - second.sequence;
  })
);

export const offerSelector = createSelector(offersSelector, state =>
  state.offers.sort((first, second) => first.sequence - second.sequence)
);

export const offerHasMoreSelector = createSelector(
  offersSelector,
  state => state.hasMore
);
