const variables = {
  gutters: {
    gut5: '0.4394rem',
    gut10: '0.879rem',
    gut15: '1.3183rem',
    gut20: '1.758rem',
    gut25: '2.197rem',
    gut30: '2.636rem',
    gut50: '4.395rem'
  },

  radius: {
    base: '3px',
    md: '5px',
    xxxl: '20px',
    round: '50%'
  },

  sizes: {
    listLogoWidth: '140px',
    listLogoHeight: '150px',
    logoImgWidth: '20rem',
    headerHeight: '13.7rem',
    headerHeightSm: '9rem',
    cardHeightWithoutType: '26rem',
    cardHeight: '31.3rem',
    cardWidth: '27rem',
    cardImageHeight: '18.2rem',
    sidebarWidth: '102px',
    filter: '60px',
    letterRound: '48px',
    itemText: '15rem',
    cardTitle: '24rem',
    downloadAppWidth: '211px',
    downloadAppHeight: '177px',
    qrCode: '98px',
    modalWidth: '50rem',
    modalHeight: '90vh',
    locationIconWidth: '50px',
    locationIconHeight: '33px',
    modalBtnWidth: '340px',
    sliderImg: '360px',
    closeIconWrapper: '56px',
    closeIcon: '18px',
    sidebarIcon: '28px',
    sidebarItem: '90px',
    modalBtnBigWidth: '471px',
    modalImgWidth: '22.5rem',
    btnBigWidth: '211px',
    searchInputHeight: '80px',
    searchIconHeight: '34px'
  }
};

export default variables;
