import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { RESIDENT_TYPES } from '../../constants';
import categoryService from '../../services/CategoryService';
import entertainmentsService from '../../services/EntertainmentsService';
import {
  appendEntertainments,
  ENTERTAINMENT_CATEGORIES_GET,
  ENTERTAINMENT_GET,
  ENTERTAINMENT_INFO_GET,
  ENTERTAINMENT_TOP_GET,
  entertainmentCategoriesSet,
  ENTERTAINMENTS_FILTER,
  ENTERTAINMENTS_GET,
  entertainmentsInfoSet,
  setEntertainmentError,
  setEntertainments,
  setTopEntertainments
} from '../actions/entertainmentActions';
import { singleResidentModalSet } from '../actions/modalActions';
import { setShops } from '../actions/shopActions';
import { paginationDataSelector } from '../selectors/entertainmentsSelectors';

function* getEntertainments({ payload }) {
  try {
    const { next, count } = yield select(paginationDataSelector);
    const { data } = yield call(
      entertainmentsService.getEntertainments,
      {
        title__wildcard: `${payload.letter}*`,
        ...payload
      },
      next
    );
    yield put(next || count ? appendEntertainments(data) : setShops(data));
  } catch (e) {
    yield put(setEntertainmentError());
  }
}

function* getEntertainment({ payload }) {
  try {
    const { data } = yield call(
      entertainmentsService.getEntertainment,
      payload
    );
    yield put(singleResidentModalSet(data, RESIDENT_TYPES.ENTERTAINMENT));
  } catch (e) {
    yield put(setEntertainmentError());
  }
}

function* getEntertainmentInfo() {
  try {
    const { data } = yield call(entertainmentsService.getInfo);
    yield put(entertainmentsInfoSet(data));
  } catch (e) {
    yield put(setEntertainmentError());
  }
}

export function* entertainmentCategoriesGet() {
  try {
    const { data } = yield call(categoryService.getEntertainmentCategories);
    yield put(entertainmentCategoriesSet(data.categories));
  } catch (error) {
    yield put(setEntertainmentError());
  }
}
function* filterEntertainemtns({ payload }) {
  try {
    const { data } = yield call(entertainmentsService.getEntertainments, {
      title__wildcard: `${payload.letter}*`,
      ...payload
    });
    yield put(setEntertainments(data));
  } catch (e) {
    yield put(setEntertainmentError());
  }
}

function* getTopEntertainments() {
  try {
    const { data } = yield call(entertainmentsService.getTopEntertainments);
    yield put(setTopEntertainments(data.results));
  } catch (e) {
    yield put(setEntertainmentError());
  }
}

export default function* entertainmentSaga() {
  yield all([
    takeLatest(ENTERTAINMENTS_GET, getEntertainments),
    takeLatest(ENTERTAINMENT_GET, getEntertainment),
    takeLatest(ENTERTAINMENT_INFO_GET, getEntertainmentInfo),
    takeLatest(ENTERTAINMENT_CATEGORIES_GET, entertainmentCategoriesGet),
    takeLatest(ENTERTAINMENTS_FILTER, filterEntertainemtns),
    takeLatest(ENTERTAINMENT_TOP_GET, getTopEntertainments)
  ]);
}
