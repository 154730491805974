import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';

const Image = ({ src, alt, isLazyLoad, css, ...props }) => {
  if (!src) {
    return null;
  }

  const imagePath = src.match(/^https?|file/)
    ? src
    : process.env.REACT_APP_IMAGE_BASE_URL + src;
  return isLazyLoad ? (
    <LazyLoad once height={200}>
      <img css={css} src={imagePath} alt={alt} {...props} />
    </LazyLoad>
  ) : (
    <img css={css} src={imagePath} alt={alt} {...props} />
  );
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  isLazyLoad: PropTypes.bool,
  css: PropTypes.object
};

Image.defaultProps = {
  alt: '',
  isLazyLoad: false
};

export default Image;
