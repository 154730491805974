export const TOGGLE_FLOOR = 'TOGGLE_FLOOR';
export const RESET_ALL = 'RESET_ALL';
export const GET_FLOORS = 'GET_FLOORS';
export const SET_FLOORS = 'SET_FLOORS';
export const FLOORS_ERROR = 'FLOORS_ERROR';

export const toggleFloor = payload => ({
  type: TOGGLE_FLOOR,
  payload
});

export const resetAll = () => ({
  type: RESET_ALL
});

export const getFloors = () => ({
  type: GET_FLOORS
});

export const setFloors = payload => ({
  type: SET_FLOORS,
  payload
});

export const setFloorsError = payload => ({
  type: FLOORS_ERROR,
  payload
});
