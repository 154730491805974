import {
  addMonths,
  differenceInCalendarDays,
  endOfWeek,
  format,
  getMonth,
  getYear,
  isSameDay,
  isWithinInterval,
  parse,
  parseISO
} from 'date-fns';
import { enGB, srLatn } from 'date-fns/esm/locale';

import { LANGUAGES } from '../constants';
import DATE_CONSTANTS from '../constants/date';
import i18n from '../i18n';
import { adaptDate } from '../util/dateUtil';

const LOCALES = {
  [LANGUAGES.EN]: enGB,
  [LANGUAGES.SR]: srLatn
};

export const getLocale = () =>
  LOCALES[localStorage.getItem('lang') || i18n.language];

export const formatWithLocale = (date, formatStr) =>
  format(date, formatStr, {
    locale: getLocale()
  });

const currentMonthConditions = [
  {
    condition: days => days < 1,
    text: i18n.t('dateMoments.today')
  },
  {
    condition: days => days < 2,
    text: i18n.t('dateMoments.tomorrow')
  },
  {
    condition: (days, daysTillEndOfTheWeek) => days <= daysTillEndOfTheWeek,
    text: i18n.t('dateMoments.thisWeek')
  },
  {
    condition: (days, daysTillEndOfTheWeek) => days <= daysTillEndOfTheWeek + 7,
    text: i18n.t('dateMoments.nextWeek')
  }
];

export const thisMonthCondition = date => {
  const now = new Date();
  return getMonth(date) === getMonth(now) && getYear(date) === getYear(now);
};
export const nextMonthConditions = date => {
  const now = new Date();
  return (
    getMonth(date) === getMonth(addMonths(now, 1)) &&
    getYear(date) === getYear(now)
  );
};

const monthConditions = [
  {
    condition: thisMonthCondition,
    text: i18n.t('dateMoments.thisMonth')
  },
  {
    condition: nextMonthConditions,
    text: i18n.t('dateMoments.nextMonth')
  }
];

export const daysDifference = (date, now) =>
  differenceInCalendarDays(date, now, {
    unit: 'day'
  });

export const calculateDaysTillEndOfWeek = () => {
  const now = new Date();
  const endOfTheWeek = endOfWeek(now, {
    weekStartsOn: 1
  });

  return daysDifference(endOfTheWeek, now);
};

export const dateToMoment = date => {
  const eventDate = adaptDate(new Date(date));
  const now = new Date();

  const daysDiff = daysDifference(eventDate, now);

  const daysTillEndOfTheWeek = calculateDaysTillEndOfWeek();

  let moment = currentMonthConditions.find(({ condition }) =>
    condition(daysDiff, daysTillEndOfTheWeek)
  );

  if (moment) {
    return moment.text;
  }

  moment = monthConditions.find(({ condition }) => condition(eventDate));

  if (moment) {
    return moment.text;
  }

  return i18n.t('dateMoments.soon');
};

export const parseDate = date => format(parseISO(date), 'd MMM');

export const parseDateWithYear = date => format(parseISO(date), 'd MMM, y');

export const isWithinRange = (date, { start, end }) =>
  isWithinInterval(date, { start, end });

export const parseEventDate = (start_date, end_date) => {
  const startDate = convertTimeZone(new Date(start_date));
  const endDate = convertTimeZone(new Date(end_date));

  if (isSameDay(startDate, endDate)) {
    return `${formatWithLocale(startDate, 'EEEE, MMMM d')}`;
  }

  return `${formatWithLocale(startDate, 'EEEE, MMMM d')} - ${formatWithLocale(
    endDate,
    'EEEE, MMMM d'
  )}`;
};

export const formatEventTime = (start_date, end_date) => {
  const startDate = convertTimeZone(new Date(start_date));
  const endDate = convertTimeZone(new Date(end_date));

  return `${formatWithLocale(startDate, 'H:mm')} - ${formatWithLocale(
    endDate,
    'H:mm'
  )}`.toLowerCase();
};

export const formatSingleDate = dateString => {
  const date = new Date(dateString);
  return formatWithLocale(date, 'EEEE, MMMM d');
};

const convertTimeZone = time => {
  const stringDate = time;
  const date = new Date(Date.parse(stringDate));
  const offset = date.getTimezoneOffset() / 60;

  return date.setHours(date.getHours() + offset);
};

export const parseDateWithFormat = (
  date,
  dateFormat = DATE_CONSTANTS.D_M_YYYY_HH_MM_SS
) => parse(date, dateFormat, new Date());
