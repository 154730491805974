import { createSelector } from 'reselect';

const entertainmentsSelector = state => state.entertainments;

export const allEntertainmentsSelector = createSelector(
  entertainmentsSelector,
  state => state.entertainments
);

export const entertainmentInfoSelector = createSelector(
  entertainmentsSelector,
  state => state.info
);

export const entertainmentCategoriesSelector = createSelector(
  entertainmentsSelector,
  state => state.categories
);

export const entertainmentSelectedFloorsSelector = createSelector(
  entertainmentsSelector,
  state => state.selectedFloors
);

export const entertainmentSelectedCategoriesSelector = createSelector(
  entertainmentsSelector,
  state => state.selectedCategories
);

export const entertainmentsTopSelector = createSelector(
  entertainmentsSelector,
  state => state.topEntertainments
);

export const paginationDataSelector = createSelector(
  entertainmentsSelector,
  state => ({ next: state.next, count: state.count })
);
