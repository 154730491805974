import { createSelector } from 'reselect';

import { SERVICE_TYPES } from '../../constants/services';

export const selectInfoSection = state => state.infoSection;

export const accessibilityServicesSelector = createSelector(
  selectInfoSection,
  state =>
    state.services.filter(
      service => service.service_type === SERVICE_TYPES.ACCESSIBILITY
    )
);

export const serviceServicesSelector = createSelector(
  selectInfoSection,
  state =>
    state.services.filter(
      service => service.service_type === SERVICE_TYPES.SERVICE
    )
);

export const getInfoSection = createSelector(
  serviceServicesSelector,
  accessibilityServicesSelector,
  (infoServices, infoAccessibility) => ({ infoServices, infoAccessibility })
);

export const splashSelector = createSelector(selectInfoSection, state => ({
  splashScreens: state.splash,
  isReady: state.splash.length && state.splash.length === state.blobs.length
}));
