import PropTypes from 'prop-types';
import React from 'react';

import {
  FilterButton,
  FiltersWrapper
} from '../../../styles/components/ResidentLetterFilterStyle';

const ResidentLetterFilter = ({ list = [], selectedItem, setSelectedItem }) => {
  const getLettersToRender = letters =>
    letters.map(key => (
      <FilterButton
        key={key}
        onClick={() => setSelectedItem(key === selectedItem ? '' : key)}
        isSelected={key === selectedItem}
      >
        {key.toUpperCase()}
      </FilterButton>
    ));

  return (
    <FiltersWrapper>
      <div>{getLettersToRender(list)}</div>
    </FiltersWrapper>
  );
};

ResidentLetterFilter.propTypes = {
  list: PropTypes.array,
  selectedItem: PropTypes.string,
  setSelectedItem: PropTypes.func
};

export default ResidentLetterFilter;
