import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { LANGUAGES } from '../../constants';
import { RESTART_TIME } from '../../constants/restart';
import { navigationMap } from '../../routes';
import { SidebarWrapper } from '../../styles/components/SidebarStyle';
import { useCloseModal } from '../../utils/hooks';
import LanguagePicker from '../common/LanguagePicker';
import NavItem from '../common/NavItem/NavItem';

const { useHistory } = require('react-router');

const RouteIntercept = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const closeModals = useCloseModal();
  const navigation = navigationMap();
  const [firstNavItem] = navigation.values();

  const splashRedirectListener = useCallback(
    debounce(() => {
      localStorage.setItem('lang', LANGUAGES.SR);
      history.push('/splash');
      closeModals();
    }, RESTART_TIME),
    []
  );

  useEffect(
    () => {
      window.addEventListener('click', splashRedirectListener);

      return () => {
        window.removeEventListener('click', splashRedirectListener);
      };
    },
    [splashRedirectListener]
  );

  const shouldShowSidebar = () =>
    navigation.some(
      ({ to, hideSideBar }) => !hideSideBar && location.pathname === `/${to}`
    );

  return (
    <>
      {children}
      {shouldShowSidebar() && (
        <SidebarWrapper>
          {navigation
            .filter(({ excludeFromSidebar }) => !excludeFromSidebar)
            .map(link => (
              <NavItem
                key={link.name}
                link={link}
                isActive={location.pathname === `/${link.to}`}
                isFirst={firstNavItem.to === `${link.to}`}
              />
            ))}
          <LanguagePicker />
        </SidebarWrapper>
      )}
    </>
  );
};

RouteIntercept.propTypes = {
  children: PropTypes.array
};
export default RouteIntercept;
