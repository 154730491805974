import PropTypes from 'prop-types';
import React from 'react';

import ElevatorIcon from '../../assets/icons/elevator.svg';
import StairsIcon from '../../assets/icons/stairs.svg';
import { IconWrapper, ModalTitle } from '../../styles/components/ModalStyle';
import { uFlex, uGapTop30 } from '../../styles/helpers';
import SvgImage from '../common/Image/SvgImage';

const ResidentLocationDetail = ({ properties = {}, where_to_find_us, t }) => {
  const shouldRenderWhereToFindUs = () =>
    (properties && (properties.near_elevator || properties.near_stairs)) ||
    (where_to_find_us &&
      !!where_to_find_us.length &&
      !!where_to_find_us[0].closest_parking_zones.length);

  const getParkingValue = () => {
    if (
      where_to_find_us &&
      where_to_find_us.length &&
      where_to_find_us[0].closest_parking_zones.length
    ) {
      return (
        <IconWrapper>
          <SvgImage src={where_to_find_us[0].closest_parking_zones[0].logo} />
        </IconWrapper>
      );
    }
    return <></>;
  };

  return (
    <div css={uGapTop30}>
      {shouldRenderWhereToFindUs() && (
        <div>
          <ModalTitle>{t('resident.location')}</ModalTitle>
          <div css={uFlex}>
            {getParkingValue()}
            {properties && (
              <>
                {properties.near_stairs && (
                  <IconWrapper>
                    <StairsIcon />
                  </IconWrapper>
                )}
                {properties.near_elevator && (
                  <IconWrapper>
                    <ElevatorIcon />
                  </IconWrapper>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ResidentLocationDetail.propTypes = {
  properties: PropTypes.object,
  where_to_find_us: PropTypes.array,
  t: PropTypes.func
};

export default ResidentLocationDetail;
