import { createSelector } from 'reselect';

const shopSelector = state => state.shop;

export const shopsSelector = createSelector(shopSelector, state => state.shops);

export const topShopsSelector = createSelector(
  shopSelector,
  state => state.topShops
);

export const shopsInfoSelector = createSelector(
  shopSelector,
  state => state.searchInfo
);

export const shopsSelectedCategoriesSelector = createSelector(
  shopSelector,
  state => state.selectedCategories
);

export const shopsSelectedFloorsSelector = createSelector(
  shopSelector,
  state => state.selectedFloors
);

export const shopCategoriesSelector = createSelector(
  shopSelector,
  state => state.shopCategories
);

export const paginationDataSelector = createSelector(shopSelector, state => ({
  count: state.count,
  next: state.next
}));
