import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { btnBase, btnLight, btnRound, btnSm, btnWhite } from '../buttons';
import colors from '../constants/colors';
import fonts from '../constants/fonts';
import variables from '../constants/variables';
import { uGapBottom15 } from '../helpers';

export const mapIframe = css`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  color: ${colors.white};
  border: none;
  background-color: ${colors.black};
`;

export const MapOptions = styled.div`
  position: fixed;
  top: 50%;
  left: ${variables.gutters.gut10};
  transform: translateY(-50%);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${colors.white};
`;

export const optionsTitle = css`
  margin-bottom: ${variables.gutters.gut20};
  font-size: ${fonts.size.h5};
  text-transform: uppercase;
`;

export const mapFloors = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${variables.gutters.gut25};
  padding-top: ${variables.gutters.gut15};
  border-top: 1px solid ${colors.white40};
`;

export const shopBtn = css`
  ${btnBase}
  ${btnLight}
  ${btnSm}

  &:not(:last-of-type) {
    ${uGapBottom15}
  }
`;

export const searchBtn = css`
  ${btnBase}
  ${btnWhite}
  ${btnRound}
`;
