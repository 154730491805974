export const OFFERS_SET = 'OFFERS_SET';
export const GET_OFFERS = 'GET_OFFERS';

export const setOffers = payload => ({
  type: OFFERS_SET,
  payload
});

export function getOffers(payload) {
  return {
    type: GET_OFFERS,
    payload
  };
}
