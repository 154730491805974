import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import GalerijaLogo from '../../assets/icons/galerija-logo.svg';
import { getFloors } from '../../redux/actions/floorActions';
import colors from '../../styles/constants/colors';

const ReloadWrapper = styled.div`
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
`;

const imageStyle = css`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: auto;
`;

const Reload = () => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(getFloors());
    },
    [dispatch]
  );

  return (
    <ReloadWrapper>
      <GalerijaLogo css={imageStyle} fill={colors.white} />
    </ReloadWrapper>
  );
};

Reload.propTypes = {};

export default Reload;
