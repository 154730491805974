import axios from 'axios';
import qs from 'qs';

class HttpService {
  constructor(options) {
    this.client = axios.create(options);
  }

  attachHeaders(headers) {
    Object.assign(this.client.defaults.headers, headers);
  }
}

const options = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  }
};

export default new HttpService(options);
