export const SHOPS_GET = 'SHOPS_GET';
export const SHOPS_SET = 'SHOPS_SET';
export const SHOPS_ERROR = 'SHOPS_ERROR';
export const SHOP_GET = 'SHOP_GET';
export const SHOP_CATEGORIES_GET = 'SHOP_CATEGORIES_GET';
export const SHOP_CATEGORIES_SET = 'SHOP_CATEGORIES_SET';
export const SHOPS_TOP_SET = 'SHOPS_TOP_SET';
export const TOP_SHOPS_GET = 'TOP_SHOPS_GET';

export const SHOPS_INFO_GET = 'SHOPS_INFO_GET';
export const SHOPS_INFO_SET = 'SHOPS_INFO_SET';

export const SHOP_SELECT_CATEGORY_SET = 'SHOP_SELECT_CATEGORY_SET';
export const SHOP_SELECT_FLOOR_SET = 'SHOP_SELECT_FLOOR_SET';

export const SHOPS_APPEND = 'SHOPS_APPEND';

export const SHOPS_RESET = 'SHOPS_RESET';

export const SHOPS_FILTER = 'SHOPS_FILTER';

export const getShops = payload => ({
  type: SHOPS_GET,
  payload
});

export const setShops = payload => ({
  type: SHOPS_SET,
  payload
});

export const setShopError = payload => ({
  type: SHOPS_ERROR,
  payload
});

export const getShop = payload => ({
  type: SHOP_GET,
  payload
});

export const shopCategoriesGet = () => ({
  type: SHOP_CATEGORIES_GET
});

export const shopCategoriesSet = payload => ({
  type: SHOP_CATEGORIES_SET,
  payload
});

export const shopsInfoGet = () => ({
  type: SHOPS_INFO_GET
});

export const shopsInfoSet = payload => ({
  type: SHOPS_INFO_SET,
  payload
});

export const shopSelectCategorySet = payload => ({
  type: SHOP_SELECT_CATEGORY_SET,
  payload
});

export const shopSelectedFloorSet = payload => ({
  type: SHOP_SELECT_FLOOR_SET,
  payload
});

export const shopsTopSet = payload => ({
  type: SHOPS_TOP_SET,
  payload
});

export const appendShops = payload => ({
  type: SHOPS_APPEND,
  payload
});

export const resetShops = () => ({
  type: SHOPS_RESET
});

export const getTopShops = () => ({
  type: TOP_SHOPS_GET
});

export const filterShops = payload => ({
  type: SHOPS_FILTER,
  payload
});
