import PropTypes from 'prop-types';
import React from 'react';

import { btnBaseLight, btnBig } from '../../styles/buttons';
import { uGapBottom15, uGapRight15 } from '../../styles/helpers';

const InfoServiceItem = ({ service, handleClick }) => {
  return (
    <div
      css={[btnBaseLight, btnBig, uGapRight15, uGapBottom15]}
      onClick={() => handleClick(service)}
      role="dialog"
    >
      <div>{service.title}</div>
    </div>
  );
};

InfoServiceItem.propTypes = {
  service: PropTypes.object,
  handleClick: PropTypes.func
};

export default InfoServiceItem;
