import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { splashSliderImg } from '../../styles/components/SplashStyle';
import Image from '../common/Image/Image';
import SplashVideo from './SplashVideo';

const SplashItem = forwardRef(
  ({ isVideo, image, isOnly }, ref) =>
    isVideo ? (
      <SplashVideo video={image} ref={ref} loop={isOnly} />
    ) : (
      <Image css={splashSliderImg} src={image} />
    )
);

SplashItem.propTypes = {
  image: PropTypes.string,
  isOnly: PropTypes.bool,
  isVideo: PropTypes.bool
};

export default SplashItem;
