import styled from '@emotion/styled';

import colors from './constants/colors';
import variables from './constants/variables';

export const FilterWrapper = styled.div`
  display: flex;
  margin: 0 ${variables.sizes.sidebarWidth} -${variables.gutters.gut30} auto;
  width: fit-content;
`;

export const FilterStyled = styled.button`
  width: ${variables.sizes.filter};
  height: ${variables.sizes.filter};
  border-radius: ${variables.sizes.filter};
  background-color: ${props =>
    props.isSelected ? colors.orange : colors.lightPeriwinkle};
  display: block;
  border: none;

  > svg {
    width: 50%;
  }
`;
