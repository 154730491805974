import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { RESIDENT_TYPES } from '../../constants';
import categoryService from '../../services/CategoryService';
import DineService from '../../services/DineService';
import {
  appendDines,
  DINE_CATEGORIES_GET,
  DINE_GET,
  dineCategoriesSet,
  DINES_FILTER,
  DINES_GET,
  DINES_INFO_GET,
  dinesInfoSet,
  dinesSet
} from '../actions/dineActions';
import { singleResidentModalSet } from '../actions/modalActions';
import { paginationDataSelector } from '../selectors/dineSelectors';

function* getDines({ payload }) {
  try {
    const { next, count } = yield select(paginationDataSelector);
    const { data } = yield call(
      DineService.getDines,
      {
        title__wildcard: `${payload.letter}*`,
        ...payload
      },
      next
    );
    yield put(next || count ? appendDines(data) : dinesSet(data));
  } catch (e) {
    console.log(e);
  }
}

function* getDine({ payload }) {
  try {
    const { data } = yield call(DineService.getDine, payload);
    yield put(singleResidentModalSet(data, RESIDENT_TYPES.DINE));
  } catch (e) {
    console.log(e);
  }
}

export function* dineCategoriesGet() {
  try {
    const { data } = yield call(categoryService.getDinesCategories);
    yield put(dineCategoriesSet(data.categories));
  } catch (error) {
    console.log(error);
  }
}

function* handleGetDinesInfo() {
  try {
    const { data } = yield call(DineService.getInfo);
    yield put(dinesInfoSet(data));
  } catch (e) {
    console.log(e);
  }
}

function* handleFilterDines({ payload }) {
  try {
    const { data } = yield call(DineService.getDines, {
      title__wildcard: `${payload.letter}*`,
      ...payload
    });
    yield put(dinesSet(data));
  } catch (e) {
    console.log(e);
  }
}

export default function* dineSaga() {
  yield all([
    takeLatest(DINES_GET, getDines),
    takeLatest(DINE_GET, getDine),
    takeLatest(DINES_INFO_GET, handleGetDinesInfo),
    takeLatest(DINE_CATEGORIES_GET, dineCategoriesGet),
    takeLatest(DINES_FILTER, handleFilterDines)
  ]);
}
