import ApiService from './ApiService';

const ENDPOINTS = {
  FLOORS: '/floors/'
};

class FloorService extends ApiService {
  getFloors = allFloors =>
    this.apiClient.get(ENDPOINTS.FLOORS, {
      params: { all_floors: allFloors }
    });
}

export const floorService = new FloorService();
