import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './renderer/App';
import store from './renderer/redux/store';
import { initAllFoors } from './renderer/utils/get-all-floors';
import { initMacAddress } from './renderer/utils/get-mac';

initMacAddress();
initAllFoors();

Sentry.init({
  dsn:
    'https://1add260f84d8497989596a1e8333b4eb@o461471.ingest.sentry.io/5464894',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root") // eslint-disable-line
);
