import PropTypes from 'prop-types';
import React from 'react';

import {
  ResidentTopContainer,
  ResidentTopListWrapper
} from '../../styles/components/ResidentTopListStyle';
import {
  uGapBottom30,
  uGapBottom50,
  uTextUppercase
} from '../../styles/helpers';
import ResidentTopListItem from './ResidentTopListItem';

const ResidentTopList = ({ onClick, title, list }) =>
  list.length ? (
    <div css={uGapBottom50}>
      <h4 css={[uGapBottom30, uTextUppercase]}>{title}</h4>
      <ResidentTopContainer>
        <ResidentTopListWrapper>
          {list.map(item => (
            <ResidentTopListItem
              {...item}
              key={item.id}
              handleClick={onClick}
            />
          ))}
        </ResidentTopListWrapper>
      </ResidentTopContainer>
    </div>
  ) : (
    <></>
  );

ResidentTopList.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  list: PropTypes.array
};

export default ResidentTopList;
