import { css } from '@emotion/core';

import variables from './constants/variables';

export const gridBase = css`
  display: flex;
  flex-wrap: wrap;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const gridGap = css`
  grid-gap: ${variables.gutters.gut15};
`;

export const gridFull = css`
  grid-column: span 12;
`;

export const gridHalf = css`
  grid-column: span 6;
`;
