import { all, call, delay, put, takeLatest } from 'redux-saga/effects';

import commonService from '../../services/CommonService';
import {
  ALERT_NOTIFICATION_GET,
  COMMON_DATA_GET,
  setAlertNotification,
  setAlertNotificationError,
  setCommonDataError
} from '../actions/commonActions';
import { dinesHaveYouTriedSet } from '../actions/dineActions';
import {
  blobsSet,
  setInfoSection,
  SPLASH_SCREEN_GET,
  splashScreenGet,
  splashScreenSet
} from '../actions/infoSectionActions';
import { GET_OFFERS, setOffers } from '../actions/offerActions';

function* getAlertNotifications() {
  try {
    const { data } = yield call(commonService.getAlertNotifications);

    yield put(setAlertNotification(data.alert_notification));
  } catch (e) {
    yield put(setAlertNotificationError());
  }
}

function* getOffers({ payload: { offset } }) {
  try {
    const { data } = yield call(commonService.getOffers, offset);

    yield put(setOffers({ offers: data, offset }));
  } catch (e) {
    yield put(setAlertNotificationError());
  }
}

function* getCommonData() {
  try {
    const { data } = yield call(commonService.getCommonData);

    yield put(setInfoSection(data.services));
    yield put(dinesHaveYouTriedSet(data.have_you_tried_dines));
  } catch (e) {
    yield put(setCommonDataError());
  }
}

function* getSplashScreen() {
  try {
    const { data } = yield call(commonService.getSplash);
    yield put(splashScreenSet(data));
    const blobs = yield call(commonService.getBlobs, data);
    yield put(blobsSet(blobs));
  } catch {
    yield delay(6000);
    yield put(splashScreenGet());
  }
}

export default function* commonSaga() {
  yield all([
    takeLatest(ALERT_NOTIFICATION_GET, getAlertNotifications),
    takeLatest(COMMON_DATA_GET, getCommonData),
    takeLatest(SPLASH_SCREEN_GET, getSplashScreen),
    takeLatest(GET_OFFERS, getOffers)
  ]);
}
