import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import { setFocusedResidentItem } from '../../../redux/actions/mapActions';
import AlphabetList from '../AlphabetList/AlphabetList';
import ResidentItem from './ResidentItem';

const ResidentList = ({ items, onItemClick, history, type }) => {
  const dispatch = useDispatch();

  const getItemLetter = item => {
    return item.title;
  };

  const onFocus = useCallback(
    (event, item) => {
      event.stopPropagation();
      dispatch(setFocusedResidentItem({ ...item, type }));

      history.push(`/map?id=${item.id}`);
    },
    [dispatch, history, type]
  );

  return (
    <>
      <AlphabetList
        items={items}
        getItemLetter={getItemLetter}
        renderItem={resident => (
          <ResidentItem
            key={resident.id}
            item={resident}
            onClick={onItemClick}
            onFocus={onFocus}
          />
        )}
      />
    </>
  );
};

ResidentList.propTypes = {
  items: PropTypes.array,
  onItemClick: PropTypes.func,
  history: PropTypes.object,
  type: PropTypes.string
};

export default withRouter(ResidentList);
