import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LogoImg from '../../assets/icons/galerija-logo.svg';
import { getAlertNotification } from '../../redux/actions/commonActions';
import { getEvents } from '../../redux/actions/eventActions';
import { getFloors } from '../../redux/actions/floorActions';
import { getOffers } from '../../redux/actions/offerActions';
import { alertNotificationsSelector } from '../../redux/selectors/commonSelectors';
import { isOnlineSelector } from '../../redux/selectors/networkSelector';
import { HomeContainer, logoImgStyle } from '../../styles/components/HomeStyle';
import { Header } from '../../styles/container';
import { uBkgHeader } from '../../styles/helpers';
import AlertNotifications from '../alertNotification/AlertNotifications';
import OffersList from '../common/home/OffersList';
import TrendingEventsList from '../common/home/TrendingEventsList';
import DownloadApp from '../DownloadApp';

const Home = () => {
  const dispatch = useDispatch();
  const online = useSelector(isOnlineSelector);
  const { alertNotification, alertNotificationError } = useSelector(
    alertNotificationsSelector
  );

  useEffect(
    () => {
      dispatch(getAlertNotification());
      dispatch(getEvents());
      dispatch(getOffers({ offset: 0 }));
      dispatch(getFloors());
    },
    [dispatch, online]
  );

  return (
    <>
      <Header css={uBkgHeader}>
        <LogoImg css={logoImgStyle} />
      </Header>
      <AlertNotifications
        alertNotification={alertNotification}
        alertNotificationError={alertNotificationError}
      />
      <HomeContainer>
        <OffersList />
        <TrendingEventsList />
        <DownloadApp />
      </HomeContainer>
    </>
  );
};

export default Home;
