import { css } from '@emotion/core';
import styled from '@emotion/styled';

import colors from '../constants/colors';
import { baseFontSizeMd } from '../constants/fonts';
import variables from '../constants/variables';

export const logoImgStyle = css`
  width: ${variables.sizes.logoImgWidth};
  fill: ${colors.white};
`;

export const NotificationWrapper = styled.div`
  padding: ${variables.gutters.gut20} ${variables.gutters.gut30};
  background-color: ${colors.error};
`;

export const NotificationText = styled.p`
  color: ${colors.white};
  font-size: ${baseFontSizeMd};
`;

export const HomeContainer = styled.div`
  padding: ${variables.gutters.gut30};
`;
