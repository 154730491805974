import React from 'react';
import { useSelector } from 'react-redux';

import { modalDataSelector } from '../../../redux/selectors/modalSelectors';
import { useCloseModal } from '../../../utils/hooks';
import Modal from './Modal';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

const CommonModal = () => {
  const { header, body, footer, isVisible, style, hideX } = useSelector(
    modalDataSelector
  );

  const onClose = useCloseModal();

  return (
    <Modal style={style} open={isVisible} onClose={onClose} hideX={hideX}>
      {isVisible && <ModalHeader>{header}</ModalHeader>}
      {isVisible && <ModalBody>{body}</ModalBody>}
      {isVisible && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  );
};

CommonModal.propTypes = {};

export default CommonModal;
