import { combineReducers } from 'redux';

import commonReducer from './common/commonReducer';
import dineReducer from './dineReducer';
import entertainmentsReducer from './EntertainmentsReducer';
import eventsReducer from './eventsReducer';
import floorsReducer from './floorsReducer';
import infoSectionReducer from './infoSectionReducer';
import mapReducer from './mapReducer';
import modalReducer from './modalReducer';
import networkReducer from './networkReducer';
import offerReducer from './offerReducer';
import shopReducer from './shopReducer';

export default combineReducers({
  shop: shopReducer,
  offer: offerReducer,
  common: commonReducer,
  events: eventsReducer,
  modal: modalReducer,
  floors: floorsReducer,
  infoSection: infoSectionReducer,
  entertainments: entertainmentsReducer,
  dine: dineReducer,
  map: mapReducer,
  network: networkReducer
});
