import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RESIDENT_TYPES } from '../../constants';
import Loader from './Loader';

const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Loading = ({ type }) => {
  const { t } = useTranslation();

  const getLoadingText = () => {
    switch (type) {
    case RESIDENT_TYPES.SHOP:
      return t('loading.shop');
    case RESIDENT_TYPES.DINE:
      return t('loading.dine');
    case RESIDENT_TYPES.ENTERTAINMENT:
      return t('loading.entertainment');
    default:
      return t('loading.splash');
    }
  };

  return (
    <LoaderWrapper>
      <Loader />
      <p>{t(getLoadingText())}</p>
    </LoaderWrapper>
  );
};

Loading.defaultProps = {
  type: 'splash'
};

Loading.propTypes = {
  type: PropTypes.string
};

export default Loading;
