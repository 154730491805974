import { INFO_SECTION_SLIDER_PROPERTIES } from '../config';

export const defaultSliderSettings = {
  arrows: false,
  infinite: true,
  speed: INFO_SECTION_SLIDER_PROPERTIES.SPEED,
  autoplaySpeed: INFO_SECTION_SLIDER_PROPERTIES.AUTOPLAY_SPEED,
  adaptiveHeight: true
};

export const DEFUALT_SPLASH_SLIDER_SPEED = 10000;
