import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useGetSplashes from '../../hooks/useGetSplashes';
import useRedirect from '../../hooks/useRedirect';
import mapService from '../../services/MapService';
import {
  splashScreenWrapper,
  SplashText,
  splashTextWrapper
} from '../../styles/components/SplashStyle';
import DotSlider from '../common/DotSlider/DotSlider';
import Loading from '../loader/Loading';
import SplashItem from '../splash/SplashItem';

const Splash = () => {
  const [splashScreens, isSplashReady] = useGetSplashes();
  const eventClick = useRedirect('/home');
  const { t } = useTranslation();

  const videoRefs = useMemo(
    () => splashScreens.map(() => ({ ref: React.createRef() })),
    [splashScreens]
  );

  const addClickListener = useCallback(
    () => {
      window.document.addEventListener('click', eventClick);
      return () => window.document.removeEventListener('click', eventClick);
    },
    [eventClick]
  );

  const isVideo = useCallback(index => splashScreens[index].isVideo, [
    splashScreens
  ]);

  const resetPreviousVideo = index => {
    videoRefs[index].ref.current.pause();
    videoRefs[index].ref.current.currentTime = 0;
  };

  const playVideoAtIndex = useCallback(
    async index => {
      await videoRefs[index].ref.current.play();
    },
    [videoRefs]
  );

  useEffect(
    () => {
      const removeListener = addClickListener();
      mapService.reload();
      return () => removeListener();
    },
    [] // eslint-disable-line
  );

  useEffect(
    () => {
      if (isSplashReady && isVideo(0)) {
        playVideoAtIndex(0);
      }
    },
    [isSplashReady]
  );

  const afterChangeSlide = index => {
    const previousIndex = index === 0 ? videoRefs.length - 1 : index - 1;
    if (isVideo(previousIndex)) {
      resetPreviousVideo(previousIndex);
    }
    if (isVideo(index)) {
      playVideoAtIndex(index);
    }
  };

  return isSplashReady ? (
    <>
      <DotSlider
        css={splashScreenWrapper}
        autoplay
        dotClickDisabled
        afterChange={afterChangeSlide}
        items={splashScreens}
      >
        {splashScreens.map((item, index) => (
          <SplashItem
            key={item.id}
            {...item}
            ref={videoRefs[index].ref}
            isOnly={splashScreens.length === 1}
          />
        ))}
      </DotSlider>
      <div style={splashTextWrapper}>
        <SplashText>{t('splash.touch')}</SplashText>
      </div>
    </>
  ) : (
    <Loading />
  );
};

export default Splash;
