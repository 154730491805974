import { isEqual } from 'date-fns';
import { createSelector } from 'reselect';

import { adaptDate, findDateClosestToNow } from '../../util/dateUtil';

const eventsSelector = state => state.events;

export const getHappeningNowEvent = createSelector(eventsSelector, state => {
  const { events } = state;

  const mostRecentDate = findDateClosestToNow(events, 'start_date');
  return events.find(event =>
    isEqual(adaptDate(new Date(event.start_date)), mostRecentDate)
  );
});

export const allEventsSelector = createSelector(
  eventsSelector,
  state => state.events
);

export const trendingEventsSelector = createSelector(eventsSelector, state =>
  state.events.filter(event => event.trending)
);

export const getUpcomingEvents = createSelector(
  eventsSelector,
  getHappeningNowEvent,
  (state, happeningNowEvent) =>
    state.events.filter(event => event.id !== happeningNowEvent.id).slice(0, 8)
);
