import PropTypes from 'prop-types';
import React from 'react';

import {
  ListCard,
  ListCardDescBig,
  ListCardDescription,
  ListCardImg,
  ListCardTextWrapper,
  ListCardTitle
} from '../../styles/components/ListCardStyle';
import { uGapBottom15 } from '../../styles/helpers';
import Image from './Image/Image';

const CardItem = ({ title, description, image, ...props }) => {
  return (
    <ListCard css={uGapBottom15} type="button" {...props}>
      <ListCardTextWrapper>
        <ListCardTitle>{title}</ListCardTitle>
        <ListCardDescription css={ListCardDescBig}>
          {description}
        </ListCardDescription>
      </ListCardTextWrapper>
      <Image css={ListCardImg} src={image} isLazyLoad />
    </ListCard>
  );
};

CardItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string
};

export default CardItem;
