import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { gridBase, gridGap } from '../../../styles/grid';
import { uGapBottom15, uGapBottom25 } from '../../../styles/helpers';

const transformListToGroup = (items, getItemLetter) => {
  const alphabetical = items.reduce((groups, item) => {
    const firstLetter = getItemLetter(item)
      .charAt(0)
      .toUpperCase();
    if (!groups[firstLetter]) {
      groups[firstLetter] = [];
    }
    groups[firstLetter].push(item);
    return groups;
  }, {});

  const ordered = {};
  Object.keys(alphabetical)
    .sort()
    .forEach(key => {
      ordered[key] = alphabetical[key];
    });

  return ordered;
};

const AlphabetList = ({ items, getItemLetter, renderItem }) => {
  const [alphabetList, setAlphabetList] = useState({});

  useEffect(
    () => {
      setAlphabetList(transformListToGroup(items, getItemLetter));
    },
    [items, getItemLetter]
  );

  return Object.keys(alphabetList).map(key => {
    return (
      <AlphabetGroup
        key={key}
        letter={key}
        items={alphabetList[key]}
        renderItem={renderItem}
      />
    );
  });
};

const AlphabetGroup = ({ letter, items, renderItem }) => {
  return (
    <div>
      <p css={uGapBottom15}>
        <strong>{letter}</strong>
      </p>
      <div css={[uGapBottom25, gridBase, gridGap]}>
        {items.map(item => renderItem(item))}
      </div>
    </div>
  );
};

AlphabetGroup.propTypes = {
  letter: PropTypes.string,
  items: PropTypes.array,
  renderItem: PropTypes.func
};

AlphabetList.propTypes = {
  items: PropTypes.array,
  getItemLetter: PropTypes.func,
  renderItem: PropTypes.func
};

export default AlphabetList;
