import styled from '@emotion/styled';

import variables from '../constants/variables';

export const ResidentTopContainer = styled.div`
  overflow: auto;
  display: flex;
  margin-left: -${variables.gutters.gut30};
  padding-left: ${variables.gutters.gut30};
  margin-right: -${variables.gutters.gut30};
`;

export const ResidentTopListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: calc(
    ${variables.sizes.sidebarWidth} + ${variables.gutters.gut30}
  );
`;
