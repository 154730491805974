export const TRENDING_EVENTS_SET = 'TRENDING_EVENTS_SET';
export const EVENTS_GET = 'EVENTS_GET';
export const EVENTS_SET = 'EVENTS_SET';
export const EVENTS_ERROR = 'EVENTS_ERROR';

export function setTrendingEvents(payload) {
  return {
    type: TRENDING_EVENTS_SET,
    payload
  };
}

export function getEvents() {
  return {
    type: EVENTS_GET
  };
}

export function setEvents(payload) {
  return {
    type: EVENTS_SET,
    payload
  };
}

export function setEventsError(payload) {
  return {
    type: EVENTS_ERROR,
    payload
  };
}
