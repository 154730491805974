import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { splashScreenGet } from '../redux/actions/infoSectionActions';
import { splashSelector } from '../redux/selectors/infoSectionSelector';

const useGetSplashes = () => {
  const { splashScreens, isReady } = useSelector(splashSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!splashScreens.length) {
      dispatch(splashScreenGet());
    }
    return () => {
      dispatch(splashScreenGet());
    };
  }, []);

  return [isReady ? splashScreens : [], isReady];
};

export default useGetSplashes;
