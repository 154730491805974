import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getEvents } from '../../redux/actions/eventActions';
import { modalEventContentSet } from '../../redux/actions/modalActions';
import { allEventsSelector } from '../../redux/selectors/eventsSelector';
import { isOnlineSelector } from '../../redux/selectors/networkSelector';
import { Header, PageContainer } from '../../styles/container';
import { uBkgPink, uTextUppercase } from '../../styles/helpers';
import EventTimeline from '../Events/EventTimeline';
import TrendingEventsList from '../Events/TrendingEventsList';

const Events = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const online = useSelector(isOnlineSelector);
  const events = useSelector(allEventsSelector);
  useEffect(
    () => {
      dispatch(getEvents());
    },
    [dispatch, online]
  );

  const getTrendingEvents = useMemo(
    () => events.filter(event => event.trending),
    [events]
  );

  const handleClick = useCallback(
    data =>
      dispatch(modalEventContentSet(events.find(event => event.slug === data))),
    [dispatch, events]
  );

  return (
    <>
      <Header css={uBkgPink}>
        <h1 css={uTextUppercase}>{t('events.events')}</h1>
      </Header>
      <PageContainer>
        <TrendingEventsList
          trendingEvents={getTrendingEvents}
          onEventClick={handleClick}
        />
        <EventTimeline events={events} />
      </PageContainer>
    </>
  );
};

export default Events;
