import { EVENTS_SET, TRENDING_EVENTS_SET } from '../actions/eventActions';
import createReducer from '../util';

const initialState = {
  trendingEvents: [],
  events: []
};

export default createReducer(
  {
    [TRENDING_EVENTS_SET]: setTrendingEvents,
    [EVENTS_SET]: setEvents
  },
  initialState
);

function setTrendingEvents(state, action) {
  return {
    ...state,
    trendingEvents: action.payload
  };
}

function setEvents(state, action) {
  return {
    ...state,
    events: action.payload
  };
}
