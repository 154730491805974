import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { modalEventContentSet } from '../../redux/actions/modalActions';
import {
  ListCard,
  ListCardDescription,
  ListCardImg,
  ListCardTextWrapper,
  ListCardTitle,
  ListCardType
} from '../../styles/components/ListCardStyle';
import { uBkgPink, uClrLight, uGapBottom15 } from '../../styles/helpers';
import Image from '../common/Image/Image';

const Event = event => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <ListCard
      css={[uGapBottom15, uBkgPink, uClrLight]}
      type="button"
      onClick={() => dispatch(modalEventContentSet(event))}
    >
      <ListCardTextWrapper>
        <ListCardType>{t('common.event')}</ListCardType>
        <ListCardTitle>{event.title}</ListCardTitle>
        <ListCardDescription>{event.description}</ListCardDescription>
      </ListCardTextWrapper>
      <Image css={ListCardImg} src={event.image} isLazyLoad />
    </ListCard>
  );
};

export default Event;
