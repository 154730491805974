export const RESIDENT_TYPES = {
  SHOP: 'shop',
  DINE: 'dine',
  ENTERTAINMENT: 'entertainment'
};

export const RESIDENT_TYPES_LANG = {
  EVENT: { SR: 'Dešavanje', EN: 'Event' },
  OFFER: { SR: 'Ponuda', EN: 'Offer' }
};

export const LANGUAGES = {
  EN: 'en',
  SR: 'sr'
};

export const DEFAULT_RESIDENT_LIMIT = 25;
