import PropTypes from 'prop-types';
import React from 'react';

import { RESIDENT_TYPES } from '../../constants';
import {
  CardText,
  CardTitle,
  ItemTextContainer,
  ResidentItemWrapper
} from '../../styles/components/ResidentTopListItemStyle';
import colors from '../../styles/constants/colors';
import variables from '../../styles/constants/variables';
import { uGapBottom10 } from '../../styles/helpers';
import Image from '../common/Image/Image';

const ResidentTopListItem = ({
  cover,
  cover_url,
  slug,
  title,
  description,
  handleClick,
  logo,
  logo_url,
  image,
  type
}) => {
  const getBackgroundColor = residentType => {
    switch (residentType) {
    case RESIDENT_TYPES.DINE:
      return colors.brandGreen;
    case RESIDENT_TYPES.SHOP:
      return colors.brandPurple;
    case RESIDENT_TYPES.ENTERTAINMENT:
      return colors.brandYellow;
    default:
      return colors.brandPink;
    }
  };

  return (
    <ResidentItemWrapper
      onClick={() => handleClick(slug)}
      type="button"
      backgroundColor={getBackgroundColor(type)}
    >
      <ItemTextContainer>
        <CardTitle css={uGapBottom10}>{title}</CardTitle>
        <CardText>{description}</CardText>
      </ItemTextContainer>
      <Image
        src={cover || cover_url || logo || logo_url || image}
        style={{
          width: `${variables.sizes.cardWidth}`,
          height: `${variables.sizes.cardImageHeight}`
        }}
      />
    </ResidentItemWrapper>
  );
};

ResidentTopListItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  logo: PropTypes.string,
  logo_url: PropTypes.string,
  handleClick: PropTypes.func,
  slug: PropTypes.string,
  image: PropTypes.string,
  cover: PropTypes.string,
  cover_url: PropTypes.string,
  type: PropTypes.string
};

export default ResidentTopListItem;
