import { createSelector } from 'reselect';

const floorsStateSelector = state => state.floors;

export const floorsSelector = createSelector(floorsStateSelector, state =>
  state.floors.sort(
    (first, second) =>
      first.number_representation - second.number_representation
  )
);

export const selectedFloorsSelector = createSelector(
  floorsStateSelector,
  state => state.selectedFloors
);
