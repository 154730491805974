import { DEFAULT_RESIDENT_LIMIT, RESIDENT_TYPES } from '../constants';
import ApiService from './ApiService';

const ENDPOINTS = {
  ENTERTAINMENT_SEARCH: '/entertainment_search/',
  ENTERTAINMENT: '/entertainment/',
  INFO: '/residents_first_letters/',
  TOP_ENTERTAINMENT_SEARCH: '/entertainment_search/?top_entertainment=true'
};

class EntertainmentsService extends ApiService {
  getEntertainments = (params, next) =>
    this.apiClient.get(next || ENDPOINTS.ENTERTAINMENT_SEARCH, {
      params: { ...params, limit: DEFAULT_RESIDENT_LIMIT }
    });

  getEntertainment = slug =>
    this.apiClient.get(`${ENDPOINTS.ENTERTAINMENT}${slug}/`);

  getInfo = () =>
    this.apiClient.get(ENDPOINTS.INFO, {
      params: { type: RESIDENT_TYPES.ENTERTAINMENT }
    });

  getTopEntertainments = () =>
    this.apiClient.get(ENDPOINTS.TOP_ENTERTAINMENT_SEARCH);
}

export default new EntertainmentsService();
