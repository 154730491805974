import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { btnModal, btnModalBig } from '../../../styles/buttons';
import {
  ModalFilterBtn,
  ModalFilterWrapper
} from '../../../styles/components/ModalStyle';
import { uGapBottom50, uGapTop50 } from '../../../styles/helpers';
import { useCloseModal } from '../../../utils/hooks';
import ModalFilterSection from './ModalFilterSection';

export const FilterBody = ({
  categories,
  floors,
  selectedCategories,
  selectedFloors,
  selectedLetter,
  setSelectedCategory,
  setSelectedFloors,
  filterAction
}) => {
  const { t } = useTranslation();
  const closeModal = useCloseModal();
  const dispatch = useDispatch();

  const [selectedFloorsState, setSelectedFloorsState] = useState(
    selectedFloors
  );
  const [selectedCategoriesState, setSelectedCategoriesState] = useState(
    selectedCategories
  );

  const isSelectedCategory = category =>
    selectedCategoriesState.some(
      selectedCategory => selectedCategory === category
    );
  const isSelectedFloor = floor =>
    selectedFloorsState.some(selectedFloor => selectedFloor === floor);

  const handleSelectFloor = floor => {
    toggle(floor, selectedFloorsState, isSelectedFloor, setSelectedFloorsState);
  };

  const handleSelectCategory = category => {
    toggle(
      category,
      selectedCategoriesState,
      isSelectedCategory,
      setSelectedCategoriesState
    );
  };

  const toggle = (item, items, checkFunction, setFunction) => {
    const newItems = checkFunction(item)
      ? items.filter(i => i !== item)
      : [...items, item];
    setFunction(newItems);
  };

  const handleApply = () => {
    setSelectedCategory(selectedCategoriesState);
    setSelectedFloors(selectedFloorsState);
    dispatch(
      filterAction({
        letter: selectedLetter,
        categories: selectedCategoriesState.map(cat => cat.id),
        floors: selectedFloorsState.map(floor => floor.id),
        search: '**'
      })
    );
    closeModal();
  };

  const handleResetAll = () => {
    setSelectedCategory([]);
    setSelectedFloors([]);
    dispatch(
      filterAction({
        letter: selectedLetter,
        categories: [],
        floors: [],
        search: '**'
      })
    );
    closeModal();
  };

  return (
    <ModalFilterWrapper>
      <ModalFilterSection
        handleToggle={handleSelectCategory}
        title={t('filter.categories')}
        filters={categories}
        isSelected={isSelectedCategory}
      />
      <ModalFilterSection
        handleToggle={handleSelectFloor}
        title={t('filter.floor')}
        filters={floors}
        isSelected={isSelectedFloor}
      />
      <button
        css={[btnModal, btnModalBig, uGapTop50, uGapBottom50]}
        type="button"
        onClick={handleApply}
      >
        <p>{t('filter.apply')}</p>
      </button>
      <button css={ModalFilterBtn} type="button" onClick={handleResetAll}>
        <p>{t('filter.resetAll')}</p>
      </button>
    </ModalFilterWrapper>
  );
};

FilterBody.propTypes = {
  categories: PropTypes.array,
  floors: PropTypes.array,
  selectedCategories: PropTypes.array,
  selectedFloors: PropTypes.array,
  setSelectedCategory: PropTypes.func,
  setSelectedFloors: PropTypes.func,
  filterAction: PropTypes.func,
  selectedLetter: PropTypes.string
};
