import { css } from '@emotion/core';
import styled from '@emotion/styled';

import fonts from '../constants/fonts';
import variables from '../constants/variables';
import { uBkgLight, uClrDark } from '../helpers';

export const ListCardWrapperHalf = styled.div`
  display: flex;
`;

export const ListCardContainerHalf = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ListCardWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  padding-bottom: ${variables.gutters.gut10};
  margin: 0 -${variables.gutters.gut30};
`;

export const ListCard = styled.button`
  width: ${variables.sizes.cardWidth};
  height: ${props =>
    props.showType
      ? variables.sizes.cardHeight
      : variables.sizes.cardHeightWithoutType};
  text-align: left;
  ${uBkgLight};
  ${uClrDark};
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: ${variables.gutters.gut15};
  border-radius: ${variables.radius.base};
  overflow: hidden;

  > div:nth-of-type(2) {
    display: flex;
  }
`;

export const ListCardTextWrapper = styled.div`
  padding: ${variables.gutters.gut15};
`;

export const ListCardImg = css`
  height: ${variables.sizes.cardImageHeight};
  width: ${variables.sizes.cardWidth};
  object-fit: cover;
`;

export const ListCardType = styled.p`
  font-size: ${fonts.size.xs};
  text-transform: uppercase;
  font-family: ${fonts.family.bold};
  margin-bottom: ${variables.gutters.gut10};
`;

export const ListCardTitle = styled.h4`
  text-transform: uppercase;
  margin-bottom: ${variables.gutters.gut10};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: ${variables.sizes.cardTitle};
`;

export const ListCardDescription = styled.p`
  font-family: ${fonts.family.regular};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: ${variables.sizes.cardTitle};
`;

export const ListCardDescBig = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: ${variables.gutters.gut20};
  white-space: normal;
`;

export const ListCardContainer = styled.div`
  display: flex;
  padding-right: calc(
    ${variables.sizes.sidebarWidth} + ${variables.gutters.gut20}
  );
  padding-left: ${variables.gutters.gut30};
`;
