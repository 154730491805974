import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

import variables from '../../../styles/constants/variables';
import { uTextUppercase } from '../../../styles/helpers';

export const EmptyBox = styled.section`
  padding: ${variables.gutters.gut30};
  text-align: center;
`;

const ListWithEmptyState = ({ children, title, description, isEmpty }) => {
  return isEmpty ? (
    <EmptyBox>
      <h4 css={uTextUppercase}>{title}</h4>
      <p>{description}</p>
    </EmptyBox>
  ) : (
    <>{children}</>
  );
};

ListWithEmptyState.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  isEmpty: PropTypes.bool
};

export default ListWithEmptyState;
