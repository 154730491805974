import { useDispatch } from 'react-redux';

import { modalClose } from '../redux/actions/modalActions';

export const useCloseModal = () => {
  const dispatch = useDispatch();

  const handleModalClose = () => dispatch(modalClose());

  return handleModalClose;
};
