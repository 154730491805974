import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const SvgImage = ({ src, style, isLuxury = false, ...props }) => {
  const svgContainer = useRef();

  useEffect(
    () => {
      if (svgContainer.current) {
        // hack to render SVG
        // eslint-disable-next-line no-undef
        const xhr = new XMLHttpRequest();
        const uri = src.match(/^https?|file/)
          ? src
          : process.env.REACT_APP_IMAGE_BASE_URL + src;
        xhr.open('GET', uri, false);

        xhr.overrideMimeType('image/svg+xml');
        xhr.onload = () => {
          if (xhr.responseXML && !svgContainer.current.children.length) {
            const svg = xhr.responseXML.documentElement;
            svg.style.width = '100%';
            svg.style.height = '100%';
            const div = document.createElement('div');
            div.style.cssText = 'width: 100%; height: 100%;';
            const shadowRoot = div.attachShadow({ mode: 'open' });
            shadowRoot.appendChild(svg);
            svgContainer.current.appendChild(div);
          }
        };
        xhr.send('');
      }
    },
    [svgContainer, src]
  );

  return (
    <div
      ref={svgContainer}
      css={[style]}
      {...props}
      fill={isLuxury ? 'black' : 'white'}
    />
  );
};

SvgImage.propTypes = {
  src: PropTypes.string,
  style: PropTypes.object,
  isLuxury: PropTypes.bool
};

export default SvgImage;
