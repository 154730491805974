import styled from '@emotion/styled';

import fonts, { baseFontSize } from '../constants/fonts';
import variables from '../constants/variables';
import { uClrLight } from '../helpers';

export const ResidentItemWrapper = styled.button`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.backgroundColor};
  height: ${variables.sizes.cardHeight};
  width: ${variables.sizes.cardWidth};
  border: none;
  margin-right: ${variables.gutters.gut15};
`;

export const ItemTextContainer = styled.div`
  text-align: left;
  ${uClrLight};
  width: 100%;
  padding: ${variables.gutters.gut20};
  flex: 1;
`;

export const CardTitle = styled.h4`
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: ${variables.sizes.cardTitle};
`;

export const CardText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: ${fonts.size.md};
  font-family: ${fonts.family.regular};
  font-size: ${baseFontSize};
`;
