export const ALERT_NOTIFICATION_GET = 'ALERT_NOTIFICATION_GET';
export const ALERT_NOTIFICATION_SET = 'ALERT_NOTIFICATION_SET';
export const ALERT_NOTIFICATION_ERROR = 'ALERT_NOTIFICATION_ERROR';

export const COMMON_DATA_GET = 'COMMON_DATA_GET';
export const COMMON_DATA_SET = 'COMMON_DATA_SET';
export const COMMON_DATA_ERROR = 'COMMON_DATA_ERROR';

export function getAlertNotification() {
  return {
    type: ALERT_NOTIFICATION_GET
  };
}

export function setAlertNotification(payload) {
  return {
    type: ALERT_NOTIFICATION_SET,
    payload
  };
}

export function setAlertNotificationError(payload) {
  return {
    type: ALERT_NOTIFICATION_ERROR,
    payload
  };
}

export function getCommonData() {
  return {
    type: COMMON_DATA_GET
  };
}

export function setCommonDataError(payload) {
  return {
    type: COMMON_DATA_ERROR,
    payload
  };
}
