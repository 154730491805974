import { DEFAULT_RESIDENT_LIMIT, RESIDENT_TYPES } from '../constants';
import ApiService from './ApiService';

const ENDPOINTS = {
  SHOPS_SEARCH: '/shops_search/',
  SHOPS: '/shops/',
  INFO: '/residents_first_letters/',
  SHOPS_SEARCH_TOP_SHOPS: '/shops_search/?top_shop=true'
};

class ShopService extends ApiService {
  getShops = (params, next) =>
    this.apiClient.get(next || ENDPOINTS.SHOPS_SEARCH, {
      params: { ...params, limit: DEFAULT_RESIDENT_LIMIT }
    });

  getShop = slug => this.apiClient.get(`${ENDPOINTS.SHOPS}${slug}/`);

  getInfo = () =>
    this.apiClient.get(ENDPOINTS.INFO, {
      params: { type: RESIDENT_TYPES.SHOP }
    });

  getTopShops = () => this.apiClient.get(ENDPOINTS.SHOPS_SEARCH_TOP_SHOPS);
}

export default new ShopService();
