import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCommonData } from '../../redux/actions/commonActions';
import { getInfoSection as getInfoSectionSelector } from '../../redux/selectors/infoSectionSelector';
import { InfoContainer, InfoWrapper } from '../../styles/components/InfoStyle';
import { Header } from '../../styles/container';
import {
  uBkgBrandYellow,
  uGapBottom20,
  uTextUppercase
} from '../../styles/helpers';
import InfoServices from '../info/InfoServices';

const InfoSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { infoServices, infoAccessibility } = useSelector(
    getInfoSectionSelector
  );

  useEffect(
    () => {
      dispatch(getCommonData());
    },
    [dispatch]
  );

  return (
    <InfoContainer>
      <Header css={uBkgBrandYellow}>
        <h1 css={uTextUppercase}>{t('info.info')}</h1>
      </Header>
      <InfoWrapper>
        <h4 css={[uTextUppercase, uGapBottom20]}>{t('info.services')}</h4>
        {!!infoServices.length && <InfoServices infoServices={infoServices} />}
        <h4 css={[uTextUppercase, uGapBottom20]}>{t('info.accessibility')}</h4>
        <InfoServices infoServices={infoAccessibility} />
      </InfoWrapper>
    </InfoContainer>
  );
};

export default InfoSection;
