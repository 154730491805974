import React from 'react';

// eslint-disable-next-line import/no-cycle
import { FilterBody } from '../../components/common/filter/FilterModal';
import SearchModal from '../../components/common/filter/SearchModal';
import {
  EventModalBody,
  EventModalFooter,
  EventModalHeader
} from '../../components/Events/EventModalContent';
import {
  InfoModalBody,
  InfoModalFooter,
  InfoModalHeader
} from '../../components/info/InfoModal';
import {
  SingleOfferModalBody,
  SingleOfferModalHeader
} from '../../components/offers/SingleOfferModalContent';
import {
  SingleResidentModalBody,
  SingleResidentModalFooter,
  SingleResidentModalHeader
} from '../../components/resident/SingleResidentModalContent';

export const MODAL_CONTENT_SET = 'MODAL_CONTENT_SET';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export function modalContentSet(payload) {
  return {
    type: MODAL_CONTENT_SET,
    payload
  };
}

export function modalClose() {
  return {
    type: MODAL_CLOSE
  };
}

export const modalEventContentSet = payload => ({
  type: MODAL_CONTENT_SET,
  isVisible: true,
  header: <EventModalHeader {...payload} />,
  body: <EventModalBody {...payload} />,
  footer: <EventModalFooter {...payload} />
});

export const singleResidentModalSet = (payload, type) => ({
  type: MODAL_CONTENT_SET,
  isVisible: true,
  header: <SingleResidentModalHeader {...payload} type={type} />,
  body: <SingleResidentModalBody {...payload} />,
  footer: <SingleResidentModalFooter item={{ ...payload, type }} />
});

export const singleOfferModalSet = payload => ({
  type: MODAL_CONTENT_SET,
  isVisible: true,
  header: <SingleOfferModalHeader {...payload} />,
  body: <SingleOfferModalBody {...payload} />,
  footer: null
});

export const modalInfoContentSet = payload => ({
  type: MODAL_CONTENT_SET,
  isVisible: true,
  header: <InfoModalHeader {...payload} />,
  body: <InfoModalBody {...payload} />,
  footer: <InfoModalFooter {...payload} />,
  hideX: true
});

export const modalFilterContentSet = payload => ({
  type: MODAL_CONTENT_SET,
  isVisible: true,
  header: null,
  body: <FilterBody {...payload} />,
  footer: null,
  style: { width: '90%', isBlured: true }
});

export const modalSearchContentSet = payload => ({
  type: MODAL_CONTENT_SET,
  isVisible: true,
  header: null,
  body: <SearchModal {...payload} />,
  footer: null,
  style: { width: '90%' }
});
