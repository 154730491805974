import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ListCardContainer,
  ListCardWrapper
} from '../../../styles/components/ListCardStyle';
import {
  uGapBottom30,
  uGapTop30,
  uTextUppercase
} from '../../../styles/helpers';
import HomeListItem from './HomeListItem';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HomeList = ({ list, onClick, title, renderLink, showType = true }) => {
  const { t } = useTranslation();
  return list.length ? (
    <>
      <Row>
        <h4 css={[uTextUppercase, uGapBottom30, uGapTop30]}>{t(title)}</h4>
        {renderLink && renderLink()}
      </Row>
      <ListCardWrapper>
        <ListCardContainer>
          {list.map(item => (
            <HomeListItem
              key={item.slug || item.id}
              {...item}
              onClick={onClick}
              showType={showType}
            />
          ))}
        </ListCardContainer>
      </ListCardWrapper>
    </>
  ) : null;
};

HomeList.propTypes = {
  list: PropTypes.array,
  onClick: PropTypes.func,
  typeKey: PropTypes.string,
  title: PropTypes.string,
  renderLink: PropTypes.func,
  showType: PropTypes.bool
};

export default HomeList;
