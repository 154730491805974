import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from '../constants';
import httpService from '../services/HttpService';

const useSetLanguage = () => {
  const { i18n } = useTranslation();

  useEffect(
    () => {
      let lang = localStorage.getItem('lang') || LANGUAGES.SR;
      if (!lang) {
        localStorage.setItem('lang', LANGUAGES.SR);
        lang = LANGUAGES.SR;
      }
      httpService.attachHeaders({ 'Accept-Language': lang });
      i18n.changeLanguage(lang);
    },
    [i18n]
  );
};

export default useSetLanguage;
