import { RESET_ALL, SET_FLOORS, TOGGLE_FLOOR } from '../actions/floorActions';
import createReducer from '../util';

const initialState = {
  floors: [],
  selectedFloors: []
};

export default createReducer(
  {
    [RESET_ALL]: resetAll,
    [TOGGLE_FLOOR]: toggleFloor,
    [SET_FLOORS]: setFloors
  },
  initialState
);

function resetAll(state) {
  return {
    ...state,
    selectedFloors: []
  };
}

function toggleFloor(state, action) {
  const selectedFloors = Array.from(state.selectedFloors);
  const index = selectedFloors.findIndex(
    category => action.payload.id === category.id
  );
  if (index !== -1) {
    selectedFloors.splice(index, 1);
  } else {
    selectedFloors.push(action.payload);
  }
  return {
    ...state,
    selectedFloors
  };
}

function setFloors(state, action) {
  return {
    ...state,
    floors: action.payload
  };
}
